import showErrorCritical from 'utils/showErrorCritical';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import Auth from 'components/auth/index';
import Disabled from 'pages/disabled/Disabled';
import axios from 'axios';
import {useEffect, useState} from 'react';
import SettingsService from 'services/api/SettingsService';
import AnalyticServices from 'services/api/AnalyticServices';
import {useLocalObservable} from 'mobx-react-lite';
import appService from 'store/appService';
import AgoraServices from 'services/api/AgoraService';
import Log from 'utils/log';

const Root = function Root() {
	const [dataLoaded, setDataLoaded] = useState(false);
	const [sentryDSN, setSentryDSN] = useState('');

	const {project, setAppInstance, setAppEnvironment} = useLocalObservable(() => appService);

	const initSentry = () => {
		Sentry.init({
			dsn: sentryDSN,
			environment: project,
			integrations: [
				new BrowserTracing({
					heartbeatInterval: 1000,
					startTransactionOnPageLoad: false,
				}),
			],
			tracesSampleRate: 0.6,
		});
	};

	const getConfig = async () => {
		await axios
			.get(`${window.location.origin}/secrets/settings.json`)
			.then(response => {
				const {data} = response;

				SettingsService.setBaseUrl(data.REACT_APP_SETTINGS_SERVICE);
				AnalyticServices.setBaseUrl(data.REACT_APP_ANALYTICS_URL);
				AgoraServices.setEnvironment(data.REACT_APP_ENVIRONMENT);
				setSentryDSN(data.REACT_APP_SENTRY_DSN);
				setAppInstance(data.REACT_APP_INSTANCE);
				setAppEnvironment(data.REACT_APP_ENVIRONMENT);

				Log.setEnvironment(data.REACT_APP_ENVIRONMENT);
			})
			.catch(error => {
				console.error('Error fetching Doppler variables:', error);
			});
	};

	useEffect(() => {
		if (sentryDSN && project) initSentry();
	}, [sentryDSN, project]);

	useEffect(() => {
		const fetchData = async () => {
			await getConfig();
			setDataLoaded(true);
		};

		fetchData();
	}, []);

	return (
		<BrowserRouter>
			<DndProvider backend={HTML5Backend}>
				<Switch>
					<Route path='/disabled'>
						<Disabled />
					</Route>
					{dataLoaded && <Auth />}
				</Switch>
			</DndProvider>
		</BrowserRouter>
	);
};

try {
	ReactDOM.render(<Root />, document.getElementById('root'));
} catch (e: any) {
	showErrorCritical(e);
}
