import {AgoraStatus} from 'models/enums/AgoraStatus.enum';
import {lowercaseKeys} from 'utils/helpers';

import appService from 'store/appService';
import userService from 'store/userService';
import roomService from 'store/roomService';
import agoraService from 'store/agoraService';
import RoomService from 'services/api/RoomService';
import ResponseStatus from 'models/enums/ResponseStatus.enum';

import SocketIoServices from 'services/SocketIoServices';
import {useEffect, useRef} from 'react';
import {useLocalObservable} from 'mobx-react-lite';
import {AgoraCreds} from 'models/room';
import UserRole from 'models/enums/UserRole.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import alertService from 'store/alertService';
import useL10n from 'l10n/useL10n';
import useUser from './useUser';
import useAnalytics from './useAnalytics';

const INTERVAL_STEP = 10000;

let agoraServices: any = null;
const IOS_12 = [12, 2];
const IOS_14 = [14, 3];

const useAgora = () => {
	const agoraStatusRef = useRef<AgoraStatus>();
	const timeoutAutoMuteRef: {current: NodeJS.Timeout | null} = useRef(null);
	const timerIdRef: {current: NodeJS.Timer | null} = useRef(null);

	const {alerts} = useL10n();
	const {
		agoraStatus,
		setAgoraMicrophoneActive,
		setAgoraMicrophones,
		setAgoraPlaybackDeviceActive,
		setAgoraPlaybackDevices,
		appEnableAgora,
		setIsAgoraLoaded,
		deviceOs,
		setIosCheckVersionAlertShown,
		appEnableWelcome,
	} = useLocalObservable(() => appService);
	const {userData, accessToken} = useLocalObservable(() => userService);
	const {roomId, updateTalkerMic, myTalker} = useLocalObservable(() => roomService);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {setIsScreenSharing, setIsMyTalkerShareScreen, setIsLocalAudioTrack} = useLocalObservable(
		() => agoraService
	);

	const {userExtraDataCheckWelcome, userExtraDataCheckOffer, userExtraDataCheckBadge} = useUser();
	const {sendAgoraAnalytics, sendAnalytics} = useAnalytics();

	const myTalkerIsModer = !!myTalker?.isModer || !!userData?.isModer;

	const getAgoraServices = async (isEnable: boolean) => {
		if (isEnable) {
			const Agora = await import('services/api/AgoraService');
			// eslint-disable-next-line new-cap
			agoraServices = new Agora.default();
			setIsAgoraLoaded(true);
		}
	};

	if (!agoraServices) agoraServices = getAgoraServices(appEnableAgora);

	const onHideAlert = () => {
		if (userData) {
			if (appEnableWelcome) {
				userExtraDataCheckWelcome(userData, false);
			} else if (!userExtraDataCheckBadge(userData)) userExtraDataCheckOffer(userData);
		}
		hideAlert();
	};

	useEffect(() => {
		if (agoraStatusRef.current !== agoraStatus) {
			agoraStatusRef.current = agoraStatus;
		}
	}, [agoraStatus]);

	useEffect(() => {
		return () => {
			if (timeoutAutoMuteRef.current) {
				clearTimeout(timeoutAutoMuteRef.current);
			}
		};
	}, []);

	const initScreenShareTrack = () => {
		agoraServices.initScreenShareTrack(setIsMyTalkerShareScreen, sendAnalytics);
	};

	const stopScreenShareTrack = () => {
		agoraServices.stopScreenShareTrack(setIsMyTalkerShareScreen);
	};

	const getMicrophones = async () => {
		const microphones = await agoraServices.getMicrophones();
		if (microphones.length > 1) {
			setAgoraMicrophones(microphones);
			setAgoraMicrophoneActive(microphones[0]);
			return microphones.length;
		}
		return null;
	};

	const getPlaybackDevices = async () => {
		const playbackDevices = await agoraServices.getPlaybackDevices();
		if (playbackDevices.length > 1) {
			setAgoraPlaybackDevices(playbackDevices);
			setAgoraPlaybackDeviceActive(playbackDevices[0]);
		}
	};

	const enableMicrophoneTheSpeaker = () => {
		if (agoraStatusRef.current === AgoraStatus.INITED) {
			timeoutAutoMuteRef.current = setTimeout(() => {
				if (userData?.id) {
					updateTalkerMic(userData.id, false);
				}
				if (roomId) {
					SocketIoServices.emitMute(roomId, false);
				}
				agoraServices.switchMicrophone(false);
			}, 350);
		}
	};

	const switchMicrophone = (value: boolean) => {
		agoraServices.switchMicrophone(value);
	};

	const destroyAgora = (changeAgoraStatus: (value: AgoraStatus) => void) => {
		agoraServices.destroy(changeAgoraStatus);
	};

	const clearAgoraStatsInterval = () => {
		if (timerIdRef.current) {
			clearInterval(timerIdRef.current);
			timerIdRef.current = null;
		}
	};

	const sendAgoraStats = () => {
		sendAgoraAnalytics([
			{event: 'getRTCStats', value: lowercaseKeys(agoraServices.getRTCStats())},
			{event: 'getLocalAudioStats', value: agoraServices.getLocalAudioStats()},
			{event: 'getLocalVideoStats', value: agoraServices.getLocalVideoStats()},
			{event: 'getRemoteAudioStats', value: agoraServices.getRemoteAudioStats()},
			{event: 'getRemoteVideoStats', value: agoraServices.getRemoteVideoStats()},
		]);
	};

	const initAgoraStats = () => {
		timerIdRef.current = setInterval(() => {
			sendAgoraStats();
		}, INTERVAL_STEP);
	};

	const initAgora = (
		creds: AgoraCreds,
		isSpeaker: boolean,
		muted: boolean,
		VODCallback: any,
		checkForSupportMicrophoneAndSpeaker: any,
		networkQualityCallback: any,
		setAgoraMicrophone: any,
		setMicrophones: any,
		changeAgoraStatus: any
	) => {
		agoraServices.init(
			creds,
			isSpeaker,
			muted,
			VODCallback,
			checkForSupportMicrophoneAndSpeaker,
			networkQualityCallback,
			setAgoraMicrophone,
			setMicrophones,
			changeAgoraStatus,
			sendAnalytics,
			setIsScreenSharing,
			setIsLocalAudioTrack
		);
	};

	const setClientRole = async (role: UserRole, muted: boolean, initMicrophoneCallback: any) => {
		await agoraServices.setClientRole(role, muted, initMicrophoneCallback, sendAnalytics);
	};

	const setMicrophone = (id: string) => {
		agoraServices.setMicrophone(id);
	};

	const setPlaybackDevice = (id: string) => {
		agoraServices.setMicrophone(id);
	};

	const checkForSupportMicrophoneAndSpeaker = async () => {
		if (deviceOs) {
			const {name, version} = deviceOs;
			if (name === 'ios') {
				// если iOs < 12.2
				if (version[0] < IOS_12[0] || (version[0] === IOS_12[0] && version[1] < IOS_12[1])) {
					showAlert({
						title: alerts.agoraCheck.microphoneAndSpeakerText,
						subtitle: alerts.agoraCheck.updateIOSText,
						buttons: [
							{
								text: alerts.agoraCheck.btn,
								type: AlertBtnType.NORMAL,
								onPress: onHideAlert,
							},
						],
						closeOnBackdropPress: true,
					});
				}

				// если 12.2 <= iOs < 14.3
				if (
					((version[0] === IOS_12[0] && version[1] === IOS_12[1]) ||
						(version[0] === IOS_12[0] && version[1] > IOS_12[1]) ||
						version[0] > IOS_12[0]) &&
					(version[0] < IOS_14[0] || (version[0] === IOS_14[0] && version[1] < IOS_14[1]))
				) {
					showAlert({
						title: alerts.agoraCheck.microphoneText,
						subtitle: alerts.agoraCheck.updateIOSText,
						buttons: [
							{
								text: alerts.agoraCheck.btn,
								type: AlertBtnType.DESTRUCTIVE,
								onPress: onHideAlert,
							},
						],
						closeOnBackdropPress: true,
					});
				}

				const microphonesLength = await getMicrophones();
				// если iOs >= 14.3
				if (
					(((myTalker?.role !== UserRole.SPEAKER && !myTalkerIsModer) ||
						((myTalker?.role === UserRole.SPEAKER || myTalkerIsModer) && microphonesLength < 2)) &&
						version[0] === IOS_14[0] &&
						version[1] === IOS_14[1]) ||
					(version[0] === IOS_14[0] && version[1] > IOS_14[1]) ||
					version[0] > IOS_14[0]
				) {
					showAlert({
						title: alerts.agoraCheck.allowAccess.title,
						subtitle: alerts.agoraCheck.allowAccess.text,
						buttons: [
							{
								text: alerts.agoraCheck.btn,
								type: AlertBtnType.NORMAL,
								onPress: onHideAlert,
							},
						],
						closeOnBackdropPress: true,
					});
				}
			}
		} else {
			showAlert({
				title: alerts.agoraCheck.allowAccess.title,
				subtitle: alerts.agoraCheck.allowAccess.text,
				buttons: [
					{
						text: alerts.agoraCheck.btn,
						type: AlertBtnType.NORMAL,
						onPress: onHideAlert,
					},
				],
				closeOnBackdropPress: true,
			});
		}
		setIosCheckVersionAlertShown(true);
	};

	const setMuteClientAudio = async (value: boolean) => {
		agoraServices.setMuteClientAudio(value);
	};

	const getStreamKey = async (body: {externalRoomId: string; externalUserId: string}) => {
		const response = await RoomService.getStreamKey(accessToken, body);
		if (response.status === ResponseStatus.SUCCESS && response.data.streamKey) {
			return response.data.streamKey;
		}
		return false;
	};

	return {
		initScreenShareTrack,
		stopScreenShareTrack,
		getMicrophones,
		getPlaybackDevices,
		enableMicrophoneTheSpeaker,
		switchMicrophone,
		destroyAgora,
		initAgora,
		setClientRole,
		setMicrophone,
		setPlaybackDevice,
		checkForSupportMicrophoneAndSpeaker,
		setMuteClientAudio,
		sendAgoraStats,
		clearAgoraStatsInterval,
		initAgoraStats,
		getStreamKey,
	};
};

export default useAgora;
