import LocalizedStrings, {LocalizedStrings as LocalizedStringsType} from 'react-localization';
import {Dictionary} from 'l10n/dictionaries/dictionary';
import en from './dictionaries/en';
import ru from './dictionaries/ru';
import hy from './dictionaries/hy';
import sr from './dictionaries/sr';
import de from './dictionaries/de';
import es from './dictionaries/es';
import ar from './dictionaries/ar';
import ro from './dictionaries/ro';
import tr from './dictionaries/tr';
import hi from './dictionaries/hi';
import pt from './dictionaries/pt';
import zh from './dictionaries/zh';
import ms from './dictionaries/ms';
import bn from './dictionaries/bn';
import th from './dictionaries/th';
import fr from './dictionaries/fr';
import fi from './dictionaries/fi';
import is from './dictionaries/is';
import ko from './dictionaries/ko';
import pl from './dictionaries/pl';
import az from './dictionaries/az';
import uz from './dictionaries/uz';
import uk from './dictionaries/uk';
import ja from './dictionaries/ja';
import nl from './dictionaries/nl';

const translations = {
	en,
	ru,
	hy,
	sr,
	de,
	es,
	ar,
	ro,
	tr,
	hi,
	pt,
	zh,
	ms,
	bn,
	th,
	fr,
	fi,
	is,
	ko,
	pl,
	az,
	uz,
	uk,
	ja,
	nl,
};

class L10n {
	private static instance: LocalizedStringsType<Dictionary>;

	public static shared = () => {
		if (!L10n.instance) {
			L10n.instance = new LocalizedStrings(translations);
		}
		return L10n.instance;
	};
}

export default L10n.shared();
