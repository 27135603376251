import axios from 'axios';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import Log from 'utils/log';

export default class TranslateService {
	static getTranslateLangs = async (token: UUID, disaplayLang: string) => {
		try {
			const {data, status} = await axios.get(`/message/translate/lang/${disaplayLang}`, {
				headers: {Authorization: `Bearer ${token}`},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Auth partner error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static translateMessages = async (
		token: UUID,
		lang: string,
		messages: {id: number; text: string}[]
	) => {
		try {
			const body = {
				messages,
				langTo: lang,
			};
			const {data, status} = await axios.post(`/message/translate`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Auth partner error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
