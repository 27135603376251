import AudioFeedbackSteps from 'models/enums/AudioFeedbackSteps';
import useL10n from 'l10n/useL10n';
import internetSpeedMeter from 'utils/internetSpeedMeter';
import useAnalytics from 'hooks/useAnalytics';
import feedbackServices from 'store/feedbackService';
import {FunctionComponent, useEffect, useRef} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import appService from 'store/appService';

const TOGGLE_ACTIVE_STEP = 1000;

const AudioStatistics: FunctionComponent = function AudioStatistics() {
	const audioStatisticsRef = useRef<HTMLDivElement>(null);
	const {audioFeedbackData, setAudioFeedbackData, toggleAudioFeedbackActiveStep} =
		useLocalObservable(() => feedbackServices);
	const {appInstance} = useLocalObservable(() => appService);

	const {startCalculatingSpeed} = internetSpeedMeter();
	const {feedback} = useL10n();
	const {sendAnalytics} = useAnalytics();

	const getSpeed = async () => {
		audioStatisticsRef.current?.classList.add('audio__statistics--loaded');
		const {kbs} = await startCalculatingSpeed(appInstance);
		setAudioFeedbackData({
			info: audioFeedbackData?.info
				? JSON.stringify({...JSON.parse(audioFeedbackData.info), speed: kbs})
				: JSON.stringify({speed: kbs}),
		});
		audioStatisticsRef.current?.classList.add('audio__statistics--loaded-done');
		sendAnalytics('ff_stream_connection_tested', {speed: kbs});
		setTimeout(() => {
			toggleAudioFeedbackActiveStep(AudioFeedbackSteps.DONE);
		}, TOGGLE_ACTIVE_STEP);
	};

	useEffect(() => {
		if (appInstance) getSpeed();
	}, [appInstance]);

	return (
		<div className='audio__statistics' ref={audioStatisticsRef}>
			<div className='audio__title'>{feedback.audio.statistics.title}</div>
			<div className='audio__statistics-loader'>
				<span />
				<span />
			</div>
		</div>
	);
};

export default observer(AudioStatistics);
