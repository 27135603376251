import ResponseStatus from 'models/enums/ResponseStatus.enum';
import stickersEmojiServices from 'store/stickersEmojiService';
import {useLocalObservable} from 'mobx-react-lite';
import {useEffect, useRef} from 'react';

import userServices from 'store/userService';
import threadService from 'store/threadService';
import modalService from 'store/modalService';
import roomService from 'store/roomService';
import appService from 'store/appService';
import toastService from 'store/toastService';
import slowModeService from 'store/slowModeService';
import suggestService from 'store/suggestService';
import chatService from 'store/chatService';

import SocketIoServices from 'services/SocketIoServices';
import SettingsService from 'services/api/SettingsService';

import useL10n from 'l10n/useL10n';
import {times} from 'data/slowModeData';
import {containsClass} from 'utils/helpers';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import MessageType from 'models/enums/MessageType.enum';
import ToasNames from 'models/enums/ToasNames.enum';
import {Sticker, StickerPack} from 'models/room';

import throttle from 'lodash/throttle';
import {ToastIconsName} from 'components/toasts/ToastIcons';

import useAnalytics from './useAnalytics';

const useStickers = () => {
	const {language} = useLocalObservable(() => appService);
	const {accessToken} = useLocalObservable(() => userServices);
	const {stickerPacks, setStickerPacks} = useLocalObservable(() => stickersEmojiServices);
	const {currentThreadId, threadMessageId} = useLocalObservable(() => threadService);
	const {visibleThreadModal} = useLocalObservable(() => modalService);
	const {roomId, myTalker} = useLocalObservable(() => roomService);
	const {userData} = useLocalObservable(() => userServices);
	const {toasts, addToast} = useLocalObservable(() => toastService);
	const {togglleVisibleStickersEmoji} = useLocalObservable(() => stickersEmojiServices);
	const {slowMode, slowModeCountdownEnable, setSlowModeTimeLeft, toggleSlowModeCountdownEnable} =
		useLocalObservable(() => slowModeService);
	const {toggleVisibleSuggestSticker} = useLocalObservable(() => suggestService);
	const {setTextareaValue, setVisibleTextareaSendBtn} = useLocalObservable(() => chatService);

	const {sendAnalytics} = useAnalytics();
	const {toasts: toastsLanguage} = useL10n();

	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
	const stickerPacksRef = useRef<StickerPack[]>([]);

	useEffect(() => {
		stickerPacksRef.current = stickerPacks;
	}, [stickerPacks]);

	const getStickersWithServices = async () => {
		if (accessToken) {
			const response = await SettingsService.getStickers(accessToken);
			if (response.status === ResponseStatus.SUCCESS) {
				setStickerPacks(response.data);
			}
		}
	};

	const findStickerByEmoji = (unified: string) => {
		const foundStickers: Sticker[] = [];
		stickerPacks.forEach(stickerPack => {
			stickerPack.stickers.forEach(sticker => {
				if (sticker.emojis.includes(unified)) {
					foundStickers.push(sticker);
				}
			});
		});
		return foundStickers;
	};

	const sendStickerToChat = throttle(
		(event: any) => {
			const eventTarget = event.target;
			if (
				eventTarget &&
				(containsClass(eventTarget, 'stickers-picker__item') ||
					containsClass(eventTarget, 'sticker-suggest__sticker-item')) &&
				roomId
			) {
				event.preventDefault();
				const stickerId = parseInt(eventTarget.dataset.stickerId, 10);
				const stickerPackackId = parseInt(eventTarget.dataset.packId, 10);

				const stickerPackIndex = stickerPacksRef.current.findIndex(
					(item: StickerPack) => item.id === stickerPackackId
				);
				const stickerIndex = stickerPacksRef.current[stickerPackIndex]?.stickers.findIndex(
					sticker => sticker.id === stickerId
				);
				if (
					(stickerId && !slowMode.global.enable && !slowMode.local.enable) ||
					(stickerId &&
						(slowMode.global.enable || slowMode.local.enable) &&
						!slowModeCountdownEnable)
				) {
					if (threadMessageId && visibleThreadModal && currentThreadId === 'new') {
						SocketIoServices.createThread({
							threadMessageId,
							messageType: MessageType.STICKER,
							stickerId,
						});
						togglleVisibleStickersEmoji(false);

						sendAnalytics('sticker_sent', {
							sticker:
								stickerPacksRef.current.length > 1
									? `p${stickerPackIndex + 1}s${stickerIndex + 1}`
									: `${stickerIndex + 1}`,
						});

						return;
					}
					SocketIoServices.emitMessage({
						externalRoomId: currentThreadId || roomId,
						stickerId,
						type: MessageType.STICKER,
					});

					if (!isMyTalkerModer && (slowMode.global.enable || slowMode.local.enable)) {
						setSlowModeTimeLeft(slowMode.local.enable ? slowMode.local.time : slowMode.global.time);
						toggleSlowModeCountdownEnable(true);
					}

					togglleVisibleStickersEmoji(false);
					toggleVisibleSuggestSticker(false);
					setVisibleTextareaSendBtn(false);

					setTextareaValue('');
					sendAnalytics('sticker_sent', {
						sticker:
							stickerPacksRef.current.length > 1
								? `p${stickerPackIndex + 1}s${stickerIndex + 1}`
								: `${stickerIndex + 1}`,
					});

					return;
				}

				if ((slowMode.global.enable || slowMode.local.enable) && slowModeCountdownEnable) {
					let findtTime = times.find(item => item.time === slowMode.local.time);
					if (slowMode.global.enable && !slowMode.local.enable) {
						findtTime = times.find(item => item.time === slowMode.global.time);
					}
					if (findtTime && !toasts.find(t => t.name && t.name === ToasNames.SENDSTICKER)) {
						const findTitle = findValueByKeyInObject<{default: string; prefix?: string}>(
							findtTime.title,
							language,
							{default: findtTime.title.ru.default}
						);
						addToast({
							name: ToasNames.SENDSTICKER,
							iconName: ToastIconsName.warning,
							liveTime: 2000,
							message: toastsLanguage.sendStickerWhenSlowModeIsOn(findTitle.default),
							cancellable: true,
						});
					}
				}
			}
		},
		250,
		{leading: true, trailing: false}
	);

	return {getStickersWithServices, findStickerByEmoji, sendStickerToChat};
};

export default useStickers;
