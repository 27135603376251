/* eslint-disable max-lines */
export const times = [
	{
		id: 0,
		title: {
			ru: {
				default: '1 секунда',
				prefix: 'ую 1 секунду',
			},
			en: {
				default: '1 second',
			},
			hy: {
				default: '1 վայրկյան',
			},
			sr: {
				default: '1 sekunda',
			},
			de: {
				default: '1 sekunde',
			},
			es: {
				default: '1 sekunda',
			},
			ar: {
				default: '1 ثانية',
			},
			ro: {
				default: '1 secundă',
			},
			tr: {
				default: '1 saniye',
			},
			hi: {
				default: '1 सेकंड',
			},
			pt: {
				default: '1 segundo',
			},
			zh: {
				default: '1秒',
			},
			ms: {
				default: '1 saat',
			},
			bn: {
				default: '1 saniye',
			},
			th: {
				default: '1 วินาที',
			},
			fr: {
				default: '1 seconde',
			},
			fi: {
				default: '1 sekunti',
			},
			is: {
				default: '1 sekúndur',
			},
			ko: {
				default: '1 초',
			},
			pl: {
				default: '1 sekunda',
			},
			az: {
				default: '1 saniyə',
			},
			uz: {
				default: '1 soniya',
			},
			uk: {
				default: '1 секунда',
			},
			ja: {
				default: '1秒',
			},
		},
		time: 1000,
	},
	{
		id: 1,
		title: {
			ru: {
				default: '2 секунды',
			},
			en: {
				default: '2 seconds',
			},
			hy: {
				default: '2 վայրկյան',
			},
			sr: {
				default: '2 sekunde',
			},
			de: {
				default: '2 sekunden',
			},
			es: {
				default: '2 segundos',
			},
			ar: {
				default: '2 ثواني',
			},
			ro: {
				default: '2 secunde',
			},
			tr: {
				default: '2 saniye',
			},
			hi: {
				default: '2 सेकेंड',
			},
			pt: {
				default: '2 segundos',
			},
			zh: {
				default: '2秒',
			},
			ms: {
				default: '2 saat',
			},
			bn: {
				default: '2 saniye',
			},
			th: {
				default: '2 วินาที',
			},
			fr: {
				default: '2 secondes',
			},
			fi: {
				default: '2 sekunti',
			},
			is: {
				default: '2 sekúndur',
			},
			ko: {
				default: '2 초',
			},
			pl: {
				default: '2 sekundy',
			},
			az: {
				default: '2 saniyə',
			},
			uz: {
				default: '2 soniya',
			},
			uk: {
				default: '2 секунди',
			},
			ja: {
				default: '2秒',
			},
		},
		time: 2000,
	},
	{
		id: 2,
		title: {
			ru: {
				default: '3 секунды',
			},
			en: {
				default: '3 seconds',
			},
			hy: {
				default: '3 վայրկյան',
			},
			sr: {
				default: '3 sekunde',
			},
			de: {
				default: '3 sekunden',
			},
			es: {
				default: '3 segundos',
			},
			ar: {
				default: '3 ثواني',
			},
			ro: {
				default: '3 secunde',
			},
			tr: {
				default: '3 saniye',
			},
			hi: {
				default: '3 सेकेंड',
			},
			pt: {
				default: '3 segundos',
			},
			zh: {
				default: '3秒',
			},
			ms: {
				default: '3 saat',
			},
			bn: {
				default: '3 saniye',
			},
			th: {
				default: '3 วินาที',
			},
			fr: {
				default: '3 secondes',
			},
			fi: {
				default: '3 sekunti',
			},
			is: {
				default: '3 sekúndur',
			},
			ko: {
				default: '3 초',
			},
			pl: {
				default: '3 sekundy',
			},
			az: {
				default: '3 saniyə',
			},
			uz: {
				default: '3 soniya',
			},
			uk: {
				default: '3 секунди',
			},
			ja: {
				default: '3秒',
			},
		},
		time: 3000,
	},
	{
		id: 3,
		title: {
			ru: {
				default: '5 секунд',
			},
			en: {
				default: '5 second',
			},
			hy: {
				default: '5 վայրկյան',
			},
			sr: {
				default: '5 sekundi',
			},
			de: {
				default: '5 sekunden',
			},
			es: {
				default: '5 segundos',
			},
			ar: {
				default: '5 ثواني',
			},
			ro: {
				default: '5 secunde',
			},
			tr: {
				default: '5 saniye',
			},
			hi: {
				default: '5 सेकेंड',
			},
			pt: {
				default: '5 segundos',
			},
			zh: {
				default: '5秒',
			},
			ms: {
				default: '5 saat',
			},
			bn: {
				default: '5 saniye',
			},
			th: {
				default: '5 วินาที',
			},
			fr: {
				default: '5 secondes',
			},
			fi: {
				default: '5 sekunti',
			},
			is: {
				default: '5 sekúndur',
			},
			ko: {
				default: '5 초',
			},
			pl: {
				default: '5 sekundy',
			},
			az: {
				default: '5 saniyə',
			},
			uz: {
				default: '5 soniya',
			},
			uk: {
				default: '5 секунд',
			},
			ja: {
				default: '5秒',
			},
		},
		time: 5000,
	},
	{
		id: 4,
		title: {
			ru: {
				default: '10 секунд',
			},
			en: {
				default: '10 seconds',
			},
			hy: {
				default: '10 վայրկյան',
			},
			sr: {
				default: '10 sekundi',
			},
			de: {
				default: '10 sekunden',
			},
			es: {
				default: '10 segundos',
			},
			ar: {
				default: '10 ثواني',
			},
			ro: {
				default: '10 secunde',
			},
			tr: {
				default: '10 saniye',
			},
			hi: {
				default: '10 सेकेंड',
			},
			pt: {
				default: '10 segundos',
			},
			zh: {
				default: '10秒',
			},
			ms: {
				default: '10 saat',
			},
			bn: {
				default: '10 saniye',
			},
			th: {
				default: '10 วินาที',
			},
			fr: {
				default: '10 secondes',
			},
			fi: {
				default: '10 sekunti',
			},
			is: {
				default: '10 sekúndur',
			},
			ko: {
				default: '10 초',
			},
			pl: {
				default: '10 sekundy',
			},
			az: {
				default: '10 saniyə',
			},
			uz: {
				default: '10 soniya',
			},
			uk: {
				default: '10 секунд',
			},
			ja: {
				default: '10秒',
			},
		},
		time: 10000,
	},
	{
		id: 5,
		title: {
			ru: {
				default: '15 секунд',
			},
			en: {
				default: '15 seconds',
			},
			hy: {
				default: '15 վայրկյան',
			},
			sr: {
				default: '15 sekundi',
			},
			de: {
				default: '15 sekunden',
			},
			es: {
				default: '15 segundos',
			},
			ar: {
				default: '15 ثواني',
			},
			ro: {
				default: '15 secunde',
			},
			tr: {
				default: '15 saniye',
			},
			hi: {
				default: '15 सेकेंड',
			},
			pt: {
				default: '15 segundos',
			},
			zh: {
				default: '15秒',
			},
			ms: {
				default: '15 saat',
			},
			bn: {
				default: '15 saniye',
			},
			th: {
				default: '15 วินาที',
			},
			fr: {
				default: '15 secondes',
			},
			fi: {
				default: '15 sekunti',
			},
			is: {
				default: '15 sekúndur',
			},
			ko: {
				default: '15 초',
			},
			pl: {
				default: '15 sekundy',
			},
			az: {
				default: '15 saniyə',
			},
			uz: {
				default: '15 soniya',
			},
			uk: {
				default: '15 секунд',
			},
			ja: {
				default: '15秒',
			},
		},
		time: 15000,
	},
	{
		id: 6,
		title: {
			ru: {
				default: '20 секунд',
			},
			en: {
				default: '20 seconds',
			},
			hy: {
				default: '20 վայրկյան',
			},
			sr: {
				default: '20 sekundi',
			},
			de: {
				default: '20 sekunden',
			},
			es: {
				default: '20 segundos',
			},
			ar: {
				default: '20 ثواني',
			},
			ro: {
				default: '20 secunde',
			},
			tr: {
				default: '20 saniye',
			},
			hi: {
				default: '20 सेकेंड',
			},
			pt: {
				default: '20 segundos',
			},
			zh: {
				default: '20秒',
			},
			ms: {
				default: '20 saat',
			},
			bn: {
				default: '20 saniye',
			},
			th: {
				default: '20 วินาที',
			},
			fr: {
				default: '20 secondes',
			},
			fi: {
				default: '20 sekunti',
			},
			is: {
				default: '20 sekúndur',
			},
			ko: {
				default: '20 초',
			},
			pl: {
				default: '20 sekundy',
			},
			az: {
				default: '20 saniyə',
			},
			uz: {
				default: '20 soniya',
			},
			uk: {
				default: '20 секунд',
			},
			ja: {
				default: '20秒',
			},
		},
		time: 20000,
	},
	{
		id: 7,
		title: {
			ru: {
				default: '30 секунд',
			},
			en: {
				default: '30 seconds',
			},
			hy: {
				default: '30 վայրկյան',
			},
			sr: {
				default: '30 sekundi',
			},
			de: {
				default: '30 sekunden',
			},
			es: {
				default: '30 segundos',
			},
			ar: {
				default: '30 ثواني',
			},
			ro: {
				default: '30 secunde',
			},
			tr: {
				default: '30 saniye',
			},
			hi: {
				default: '30 सेकेंड',
			},
			pt: {
				default: '30 segundos',
			},
			zh: {
				default: '30秒',
			},
			ms: {
				default: '30 saat',
			},
			bn: {
				default: '30 saniye',
			},
			th: {
				default: '30 วินาที',
			},
			fr: {
				default: '30 secondes',
			},
			fi: {
				default: '30 sekunti',
			},
			is: {
				default: '30 sekúndur',
			},
			ko: {
				default: '30 초',
			},
			pl: {
				default: '30 sekundy',
			},
			az: {
				default: '30 saniyə',
			},
			uz: {
				default: '30 soniya',
			},
			uk: {
				default: '30 секунд',
			},
			ja: {
				default: '30秒',
			},
		},
		time: 30000,
	},
	{
		id: 8,
		title: {
			ru: {
				default: '1 минута',
				prefix: 'ую 1 минуту',
			},
			en: {
				default: '1 minute',
			},
			hy: {
				default: '1 րոպե',
			},
			sr: {
				default: '1 minut',
			},
			de: {
				default: '1 minute',
			},
			es: {
				default: '1 minuto',
			},
			ar: {
				default: '1 دقيقة',
			},
			ro: {
				default: '1 minut',
			},
			tr: {
				default: '1 dakika',
			},
			hi: {
				default: '1 मिनट',
			},
			pt: {
				default: '1 minuto',
			},
			zh: {
				default: '1分鐘',
			},
			ms: {
				default: '1 minit',
			},
			bn: {
				default: '1 dakika',
			},
			th: {
				default: '1 นาที',
			},
			fr: {
				default: '1 minute',
			},
			fi: {
				default: '1 minuutti',
			},
			is: {
				default: '1 mínútur',
			},
			ko: {
				default: '1 분',
			},
			pl: {
				default: '1 minuta',
			},
			az: {
				default: '1 dəqiqə',
			},
			uz: {
				default: '1 daqiqa',
			},
			uk: {
				default: '1 хвилина',
			},
			ja: {
				default: '1分',
			},
		},
		time: 60000,
	},
	{
		id: 9,
		title: {
			ru: {
				default: '2 минуты',
			},
			en: {
				default: '2 minutes',
			},
			hy: {
				default: '2 րոպե',
			},
			sr: {
				default: '2 minuta',
			},
			de: {
				default: '2 minuten',
			},
			es: {
				default: '2 minutos',
			},
			ar: {
				default: '2 دقيقة',
			},
			ro: {
				default: '2 minute',
			},
			tr: {
				default: '2 dakika',
			},
			hi: {
				default: '2 मिनट',
			},
			pt: {
				default: '2 minutos',
			},
			zh: {
				default: '2分鐘',
			},
			ms: {
				default: '2 minit',
			},
			bn: {
				default: '2 dakika',
			},
			th: {
				default: '2 นาที',
			},
			fr: {
				default: '2 minutes',
			},
			fi: {
				default: '2 minuutti',
			},
			is: {
				default: '2 mínútur',
			},
			ko: {
				default: '2 분',
			},
			pl: {
				default: '2 minuty',
			},
			az: {
				default: '2 dəqiqə',
			},
			uz: {
				default: '2 daqiqa',
			},
			uk: {
				default: '2 хвилини',
			},
			ja: {
				default: '2分',
			},
		},
		time: 120000,
	},
	{
		id: 10,
		title: {
			ru: {
				default: '3 минуты',
			},
			en: {
				default: '3 minutes',
			},
			hy: {
				default: '3 րոպե',
			},
			sr: {
				default: '3 minuta',
			},
			de: {
				default: '3 minuten',
			},
			es: {
				default: '3 minutos',
			},
			ar: {
				default: '3 دقيقة',
			},
			ro: {
				default: '3 minute',
			},
			tr: {
				default: '3 dakika',
			},
			hi: {
				default: '3 मिनट',
			},
			pt: {
				default: '3 minutos',
			},
			zh: {
				default: '3分鐘',
			},
			ms: {
				default: '3 minit',
			},
			bn: {
				default: '3 dakika',
			},
			th: {
				default: '3 นาที',
			},
			fr: {
				default: '3 minutes',
			},
			fi: {
				default: '3 minuutti',
			},
			is: {
				default: '3 mínútur',
			},
			ko: {
				default: '3 분',
			},
			pl: {
				default: '3 minuty',
			},
			az: {
				default: '3 dəqiqə',
			},
			uz: {
				default: '3 daqiqa',
			},
			uk: {
				default: '3 хвилини',
			},
			ja: {
				default: '3分',
			},
		},
		time: 180000,
	},
	{
		id: 11,
		title: {
			ru: {
				default: '5 минут',
			},
			en: {
				default: '5 minutes',
			},
			hy: {
				default: '5 րոպե',
			},
			sr: {
				default: '5 minuta',
			},
			de: {
				default: '5 minuten',
			},
			es: {
				default: '5 minutos',
			},
			ar: {
				default: '5 دقيقة',
			},
			ro: {
				default: '5 minute',
			},
			tr: {
				default: '5 dakika',
			},
			hi: {
				default: '5 मिनट',
			},
			pt: {
				default: '5 minutos',
			},
			zh: {
				default: '5分鐘',
			},
			ms: {
				default: '5 minit',
			},
			bn: {
				default: '5 dakika',
			},
			th: {
				default: '5 นาที',
			},
			fr: {
				default: '5 minutes',
			},
			fi: {
				default: '5 minuutti',
			},
			is: {
				default: '5 mínútur',
			},
			ko: {
				default: '5 분',
			},
			pl: {
				default: '5 minuty',
			},
			az: {
				default: '5 dəqiqə',
			},
			uz: {
				default: '5 daqiqa',
			},
			uk: {
				default: '5 хвилин',
			},
			ja: {
				default: '5分',
			},
		},
		time: 300000,
	},
];
