import Log from 'utils/log';
import AgoraRTC, {
	ClientConfig,
	ConnectionState,
	ConnectionDisconnectedReason,
	IAgoraRTCClient,
	IAgoraRTCRemoteUser,
	IMicrophoneAudioTrack,
	ScreenVideoTrackInitConfig,
	IRemoteAudioTrack,
	IRemoteVideoTrack,
} from 'agora-rtc-sdk-ng';
import {AgoraCreds} from 'models/room';
import UserRole from 'models/enums/UserRole.enum';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';

const config: ClientConfig = {
	mode: 'live',
	codec: 'vp8',
};

const configScreenShare: ScreenVideoTrackInitConfig = {
	displaySurface: 'browser',
};

export default class AgoraServices {
	client: any | IAgoraRTCClient = null;

	microphoneTrack: IMicrophoneAudioTrack | null = null;

	localAudioTrack: IRemoteAudioTrack | null = null;

	localVideoTrack: IRemoteVideoTrack | null = null;

	screenShareTrack: any | null = null;

	uid: any = null;

	connectionId: string | null = null;

	static environmnet = 'dev';

	static setEnvironment = (value: string) => {
		AgoraServices.environmnet = value;
	};

	constructor() {
		if (AgoraServices.environmnet !== 'dev') {
			AgoraRTC.setLogLevel(4);
		}
	}

	public async init(
		creds: AgoraCreds,
		isSpeaker: boolean,
		muted: boolean,
		VODCallback: any,
		checkForSupportMicrophoneAndSpeaker: any,
		networkQualityCallback: any,
		setAgoraMicrophone: any,
		setAgoraMicrophones: any,
		changeAgoraStatus: any,
		sendAnalytics: any,
		setIsScreenSharing: any,
		setIsLocalAudio: any
	) {
		try {
			if (this.client === null) {
				this.client = AgoraRTC.createClient({
					...config,
					role: isSpeaker ? 'host' : 'audience',
				});
			}

			AgoraRTC.onMicrophoneChanged = async ({state, device}) => {
				if (this.microphoneTrack) {
					const oldMicrophones = await AgoraRTC.getMicrophones();
					let deviceActive: MediaDeviceInfo | null = null;

					if (oldMicrophones.length) {
						setAgoraMicrophones(oldMicrophones);
					}

					if (state === 'ACTIVE') {
						deviceActive = device;
					} else if (device.label === this.microphoneTrack.getTrackLabel()) {
						if (oldMicrophones.length) {
							const {0: firstDevice} = oldMicrophones;
							deviceActive = firstDevice;
						}
					}

					if (deviceActive) {
						setAgoraMicrophone(deviceActive);
						this.microphoneTrack.setDevice(deviceActive.deviceId);
					}
				}
			};

			this.client?.on(
				'user-published',
				async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
					await this.client?.subscribe(user, mediaType);
					if (mediaType === 'audio') {
						user.audioTrack?.play();
						setIsLocalAudio(true);
						if (user.audioTrack) this.localAudioTrack = user.audioTrack;
					}
					if (mediaType === 'video') {
						setIsScreenSharing(true);
						if (!document.querySelector('.chat__video-track div')) {
							const localPlayerContainer = document.createElement('div');
							document.querySelector('.chat__video-track')?.append(localPlayerContainer);
							user.videoTrack?.play(localPlayerContainer);
							if (user.videoTrack) this.localVideoTrack = user.videoTrack;
						}
					}

					AgoraRTC.onAutoplayFailed = () => {
						checkForSupportMicrophoneAndSpeaker();
					};
				}
			);

			this.client?.on('network-quality', (quality: any) => {
				networkQualityCallback(
					quality,
					this.client?.getRTCStats(),
					this.localVideoTrack !== null || this.screenShareTrack !== null
				);
			});

			this.client?.on('user-unpublished', (user: IAgoraRTCRemoteUser, type: 'audio' | 'video') => {
				if (type === 'audio') {
					user.audioTrack?.stop();
				}

				if (type === 'video') {
					user.videoTrack?.stop();
					setIsScreenSharing(false);
				}
			});

			this.client?.enableAudioVolumeIndicator();

			this.client?.on('volume-indicator', (result: any) => {
				result.forEach((volume: any) => {
					const uid = volume.uid.indexOf(':');
					if (uid) VODCallback(+volume.uid.substring(0, uid), volume.level);
				});
			});

			// https://api-ref.agora.io/en/voice-sdk/web/4.x/interfaces/iagorartcclient.html#event_connection_state_change
			// https://api-ref.agora.io/en/voice-sdk/web/4.x/globals.html#connectionstate
			this.client?.on(
				'connection-state-change',
				(
					curState: ConnectionState,
					revState: ConnectionState,
					reason: ConnectionDisconnectedReason
				) => {
					if (curState === 'DISCONNECTED') {
						sendAnalytics('user_agora_disconnected', {reason});
					}
				}
			);

			if (creds.appId && creds.token && creds.channelName && creds.talker && creds.connectionId) {
				this.connectionId = creds.connectionId;
				if (isSpeaker)
					[this.uid, this.microphoneTrack] = await Promise.all([
						this.client?.join(creds.appId, creds.channelName, creds.token, creds.connectionId),
						AgoraRTC.createMicrophoneAudioTrack(),
					]);
				else this.client?.join(creds.appId, creds.channelName, creds.token, creds.connectionId);
			}

			if (isSpeaker) {
				this.initMicrophoneTrack(muted, changeAgoraStatus, sendAnalytics);
				return;
			}

			changeAgoraStatus(AgoraStatus.INITED);
			sendAnalytics('user_agora_joined');
		} catch (error) {
			changeAgoraStatus(AgoraStatus.DESTROYED);
			Log.error('Init agora voice: ', error);
		}
	}

	public async initMicrophoneTrack(
		muted: boolean,
		initMicrophoneCallback?: any,
		sendAnalytics?: any
	) {
		if (this.client) {
			if (this.microphoneTrack === null) {
				this.microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
			}
			await this.microphoneTrack?.setEnabled(true);
			await this.client?.setClientRole('host');
			await this.client.publish(this.microphoneTrack);
			await this.microphoneTrack?.setMuted(muted);
			if (initMicrophoneCallback) {
				initMicrophoneCallback(AgoraStatus.INITED);
				if (sendAnalytics) {
					sendAnalytics('user_agora_joined');
				}
			}
		}
	}

	public async initScreenShareTrack(setIsMyTalkerShareScreen: any, sendAnalytics: any) {
		if (this.client) {
			if (this.screenShareTrack === null) {
				this.screenShareTrack = await AgoraRTC.createScreenVideoTrack(configScreenShare, 'disable');
			}

			sendAnalytics('livestream_started', {
				live_stream_type: 'RTMP',
			});

			setIsMyTalkerShareScreen(true);
			await this.client.setClientRole('host');
			await this.client.publish(this.screenShareTrack);
			const localPlayerContainer = document.createElement('div');

			document.querySelector('.chat__video-track')?.append(localPlayerContainer);
			this.screenShareTrack.play(localPlayerContainer);
		}
	}

	public async stopScreenShareTrack(setIsMyTalkerShareScreen: any) {
		if (this.client) {
			if (this.screenShareTrack) {
				await this.client.unpublish(this.screenShareTrack);
				this.screenShareTrack.close();
				this.screenShareTrack = null;
				setIsMyTalkerShareScreen(false);
			}
		}
	}

	public async getMicrophones() {
		const microphones = await AgoraRTC.getMicrophones(true);
		return microphones;
	}

	public async setMicrophone(deviceId: string) {
		if (this.microphoneTrack) {
			this.microphoneTrack.setDevice(deviceId);
		}
	}

	public async switchMicrophone(muted: boolean) {
		try {
			await this.microphoneTrack?.setMuted(muted);
		} catch (err) {
			Log.error(`error in mute mic-> ${JSON.stringify(err, null, 2)}`);
		}
	}

	public async getPlaybackDevices() {
		const playbackDevices = await AgoraRTC.getPlaybackDevices(true);
		return playbackDevices;
	}

	public async setPlaybackDevice(deviceId: string) {
		if (this.client && this.client.remoteUsers.length) {
			this.client.remoteUsers.forEach((item: any) => item.audioTrack?.setPlaybackDevice(deviceId));
		}
	}

	public setClientRole = async (
		role: UserRole,
		muted: boolean,
		initMicrophoneCallback: any,
		sendAnalytics: any
	) => {
		const isSpeaker = role === UserRole.SPEAKER;
		try {
			if (isSpeaker) {
				await this.initMicrophoneTrack(muted, initMicrophoneCallback, sendAnalytics);
			} else {
				await this.microphoneTrack?.setMuted(false);
				await this.client?.unpublish();
				await this.microphoneTrack?.setEnabled(false);
				this.microphoneTrack = null;
				await this.client?.setClientRole('audience');
			}
		} catch (err) {
			Log.error(`error in switching the client role-> ${JSON.stringify(err, null, 2)}`);
		}
	};

	public setMuteClientAudio = async (value: boolean) => {
		value ? this.localAudioTrack?.stop() : this.localAudioTrack?.play();
	};

	public getClientAudio = async () => {
		return this.localAudioTrack;
	};

	public async destroy(changeAgoraStatus: any) {
		this.microphoneTrack?.stop();
		this.microphoneTrack?.close();
		this.microphoneTrack = null;
		this.client.remoteUsers.forEach(async (user: any) => {
			if (user.audioTrack) {
				await user.audioTrack.stop();
				await user.audioTrack.close();
			}
		});
		await this.client?.leave();
		changeAgoraStatus(AgoraStatus.DESTROYED);
		this.client?.removeAllListeners();
		await this.client?.unpublish();
	}

	public getRTCStats() {
		return this.client?.getRTCStats();
	}

	public getLocalVideoStats() {
		return this.client?.getLocalVideoStats();
	}

	public getLocalAudioStats() {
		return this.client?.getLocalAudioStats();
	}

	public getRemoteAudioStats() {
		if (this.client) return Object.values(this.client.getRemoteAudioStats(this.connectionId))[0];
		return null;
	}

	public getRemoteVideoStats() {
		if (this.client) return Object.values(this.client.getRemoteVideoStats(this.connectionId))[0];
		return null;
	}
}
