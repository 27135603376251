import {localeLanguages} from 'constants/constants';
import LanguageTag from 'models/enums/LanguageTag.enum';

const getPreviousDay = (date = new Date()) => {
	const previous = new Date(date.getTime());
	previous.setDate(date.getDate() - 1);
	return previous.toLocaleDateString();
};

const getLocaleTimeMessage = (time: string) => {
	const localeTimeString = new Date(time);
	const conditions = ['AM', 'PM'];
	let locale = 'en-GB';
	let formatTimeOptions: Intl.DateTimeFormatOptions | undefined = {
		hour: 'numeric',
		minute: 'numeric',
	};
	if (conditions.some(el => localeTimeString.toLocaleTimeString().includes(el))) {
		locale = 'en-US';
		formatTimeOptions = {
			...formatTimeOptions,
			hour12: true,
		};
	}
	return localeTimeString.toLocaleTimeString(locale, formatTimeOptions);
};

const getLocaleDateMessage = (
	time: string,
	language: string,
	daysTranslate: {today: string; yesterday: string},
	visibleTime?: boolean
) => {
	const dateNow = new Date();
	const dateMessage = new Date(time);
	const localeDateStringMessage = dateMessage.toLocaleDateString();
	const localeTimeStringMessage = dateMessage.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
	});

	if (localeDateStringMessage === dateNow.toLocaleDateString()) {
		return daysTranslate.today;
	}

	if (localeDateStringMessage === getPreviousDay()) {
		return daysTranslate.yesterday;
	}

	const locale = localeLanguages.find(item => item.includes(language)) || 'en-GB';
	let formatDateOptions: Intl.DateTimeFormatOptions | undefined = {
		day: '2-digit',
		month: 'short',
	};

	if (locale === 'hy-AM') {
		formatDateOptions = {
			day: '2-digit',
			month: '2-digit',
		};
	}

	if (dateMessage.getFullYear() !== dateNow.getFullYear()) {
		formatDateOptions = {
			...formatDateOptions,
			year: 'numeric',
		};
	}

	let formatDate = dateMessage.toLocaleDateString(locale, formatDateOptions);
	if (locale === 'hy-AM') {
		formatDate = dateMessage.toLocaleDateString(undefined, formatDateOptions).replace(/\//g, '.');
	}

	if (visibleTime) {
		return formatDate.includes('г.')
			? `${formatDate.replace('г.', '').trim()} ${localeTimeStringMessage}`
			: `${formatDate}, ${localeTimeStringMessage}`;
	}

	let value = formatDate.includes('г.') ? formatDate.replace('г.', '').trim() : formatDate;
	if (language === LanguageTag.en) value = `on ${value}`;
	return value;
};

const getDateBet = (time: string) => {
	const dateNow = new Date();
	const dateMessage = new Date(time);
	const localeTimeStringMessage = dateMessage.toLocaleTimeString([], {
		hour: '2-digit',
		minute: '2-digit',
	});

	const locale = 'ru-Ru';
	let formatDateOptions: Intl.DateTimeFormatOptions | undefined = {
		day: '2-digit',
		month: '2-digit',
	};

	if (dateMessage.getFullYear() !== dateNow.getFullYear()) {
		formatDateOptions = {
			...formatDateOptions,
			year: 'numeric',
		};
	}

	const formatDate = dateMessage.toLocaleDateString(locale, formatDateOptions);

	return formatDate.includes('г.')
		? `${formatDate.replace('г.', '').trim()} ${localeTimeStringMessage}`
		: `${formatDate} ${localeTimeStringMessage}`;
};
export {getLocaleTimeMessage, getLocaleDateMessage, getDateBet};
