import axios from 'axios';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import {ChatBot} from 'models/settings';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import Log from 'utils/log';

export default class ChatbotService {
	static getChatBot = async (token: UUID) => {
		try {
			const {data, status} = await axios.get<ChatBot>(`/chatbot`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get ChatBot error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
