/* eslint-disable no-console */
import {AnalyticsProps, Talker} from 'models/room';
import roomServices from 'store/roomService';
import userServices from 'store/userService';
import AnalyticServices from 'services/api/AnalyticServices';
import useSearchParams from 'hooks/useSearchParams';
import {useLocalObservable} from 'mobx-react-lite';
import {track} from '@amplitude/analytics-browser';
import appService from 'store/appService';
import {events} from 'events/events';
import {useEffect, useRef} from 'react';
import threadService from 'store/threadService';

const useAnalytics = () => {
	const {userIdFromUrl, roomIdFromUrl, platformFromUrl, sourceFromUrl, readonlyFromUrl} =
		useSearchParams();

	const {userData, decryptedUserId} = useLocalObservable(() => userServices);
	const {roomId, myTalker} = useLocalObservable(() => roomServices);
	const threadStore = useLocalObservable(() => threadService);
	const {project} = useLocalObservable(() => appService);
	const {appEnableDecrypt} = useLocalObservable(() => appService);

	const isThread = useRef<boolean>(false);
	const roomIdRef = useRef<string>('');
	const myTalkerRef = useRef<Talker | null>(null);
	const externalId = useRef<string>('');

	useEffect(() => {
		if (roomId) roomIdRef.current = roomId;
	}, [roomId]);

	useEffect(() => {
		if (myTalker) myTalkerRef.current = myTalker;
	}, [myTalker]);

	useEffect(() => {
		isThread.current = !!threadStore.currentThreadId;
	}, [threadStore.currentThreadId]);

	useEffect(() => {
		if (userData?.externalId) externalId.current = userData?.externalId;
	}, [userData]);

	let objProps: AnalyticsProps;

	const getScreenSize = () => `${window.innerWidth}x${window.innerHeight}`;

	const getOsPlatform = () => (platformFromUrl ? platformFromUrl.toLowerCase() : 'none');

	const getProps = (props?: any) => {
		if (sourceFromUrl === 'admin') return null;
		if (userData?.externalId && roomId && readonlyFromUrl !== 'true') {
			objProps = {
				...objProps,
				user_id: appEnableDecrypt ? decryptedUserId : userData.externalId,
				event_id: roomId,
				screen_size: getScreenSize(),
				platform: getOsPlatform(),
				platformUserAgent: navigator.userAgent,
				source: isThread.current ? 'thread' : 'room',
			};

			return props
				? {
						...objProps,
						...props,
				  }
				: {...objProps};
		}

		if (userIdFromUrl && roomIdFromUrl && readonlyFromUrl !== 'true') {
			return {
				...objProps,
				user_id: appEnableDecrypt ? decryptedUserId : userIdFromUrl,
				event_id: roomIdFromUrl,
				screen_size: getScreenSize(),
				platform: getOsPlatform(),
				platformUserAgent: navigator.userAgent,
			};
		}

		if (readonlyFromUrl === 'true' && roomIdFromUrl) {
			return {
				...objProps,
				event_id: roomIdFromUrl,
				screen_size: getScreenSize(),
				platform: getOsPlatform(),
				platformUserAgent: navigator.userAgent,
				source: isThread.current ? 'thread' : 'room',
				read_only: readonlyFromUrl === 'true',
			};
		}

		return objProps;
	};

	const sendAnalytics = (eventName: string, props?: any) => {
		const projectId = {project};
		let event = eventName;
		if (project.includes('liga-')) {
			event = events.find(el => el.event === event)?.ligaEvent || eventName;
		}
		if (event && getProps(props)) {
			track(event, getProps(props));
			const userId = userData?.externalId || externalId.current || userIdFromUrl;
			userId &&
				AnalyticServices.sendTechnicalAnalytics({
					event,
					value: {
						...getProps(props),
						...projectId,
						user_id: userId,
					},
				});
		}
	};

	const sendAgoraAnalytics = (value: {event: string; value: any}[]) => {
		if (sourceFromUrl === 'admin') return;
		if (roomIdRef.current && myTalkerRef.current) {
			const userId = userData?.externalId || externalId.current || userIdFromUrl;
			const agoraStats = value.map(el => {
				return {
					...el,
					value: {
						...el.value,
						project,
						userId,
						roomId: roomIdRef.current,
						role: myTalkerRef.current?.role,
						read_only: readonlyFromUrl === 'true',
					},
				};
			});
			userId && AnalyticServices.sendAgoraAnalytics(agoraStats);
		}
	};

	return {sendAnalytics, sendAgoraAnalytics};
};

export default useAnalytics;
