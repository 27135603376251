import AppAuthStatus from 'models/enums/AppAuthStatus.enum';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import appService from 'store/appService';
import useSearchParams from 'hooks/useSearchParams';
import useUser from 'hooks/useUser';
import {qs} from 'utils/helpers';
import {useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import {Error} from 'pages/error';
import App from 'components/app';
import {Preloader} from 'components/preloader';
import LanguageTag from 'models/enums/LanguageTag.enum';
import AppInstance from 'models/enums/AppInstance.enum';
import {useHistory, useLocation} from 'react-router-dom';
import SettingsService from 'services/api/SettingsService';

const HTMLNode = document.querySelector('html');

const Auth = function Auth() {
	const {
		appAuthStatus,
		setAppAuthStatus,
		setAppApiUrls,
		setProject,
		isInternernetActive,
		setLanguage,
		setDisabledChatText,
		appInstance,
	} = useLocalObservable(() => appService);

	const {
		apikeyFromUrl,
		userIdFromUrl,
		roomIdFromUrl,
		readonlyFromUrl,
		interfaceDirectionFromUrl,
		authCodeFromUrl,
	} = useSearchParams();
	const {getAppSettings} = useUser();
	const location = useLocation();
	const history = useHistory();

	const authPartner = async (apikey: string) => {
		const response = await SettingsService.auth(apikey);
		if (response.status === ResponseStatus.SUCCESS) {
			const {antimatUrl, backendUrl, project, enableChat, disabledChatText} = response.data;

			if (!enableChat) {
				setDisabledChatText(disabledChatText);
				history.push(`/disabled${location.search}`);
				return;
			}

			if (backendUrl) {
				axios.defaults.baseURL = backendUrl;
				setAppApiUrls({backend: backendUrl});
			}

			if (antimatUrl) {
				setAppApiUrls({antimat: antimatUrl});
			}

			if (project) {
				setProject(project);
			}

			if (backendUrl && antimatUrl && project) {
				getAppSettings();
				return;
			}
		}

		setAppAuthStatus(AppAuthStatus.ERROR);
	};

	useEffect(() => {
		qs('html')?.classList.add(isMobile ? 'mobile' : 'desktop');
		if (interfaceDirectionFromUrl) HTMLNode?.setAttribute('rtl', 'true');
	}, []);

	useEffect(() => {
		if (appInstance) setLanguage(appInstance === AppInstance.SS ? LanguageTag.ru : LanguageTag.en);
	}, [appInstance]);

	useEffect(() => {
		if (
			!apikeyFromUrl ||
			(!userIdFromUrl && readonlyFromUrl !== 'true' && !authCodeFromUrl) ||
			!roomIdFromUrl
		) {
			setAppAuthStatus(AppAuthStatus.ERROR);
			return;
		}

		if (apikeyFromUrl && isInternernetActive) {
			authPartner(apikeyFromUrl);
		}
	}, [isInternernetActive]);

	switch (appAuthStatus) {
		case AppAuthStatus.ERROR:
			return <Error />;
		case AppAuthStatus.SUCCESS:
			return <App />;
		default:
			return <Preloader visible />;
	}
};

export default observer(Auth);
