import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {AnalyticsData} from 'models/responses';
import {TechnicalAnalyticsProps, AnalyticsProps, AgoraAnalyticsProps} from 'models/room';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import Log from 'utils/log';
import checkResponse from 'utils/checkResponse';

export default class AnalyticServices {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		AnalyticServices.baseUrl = baseUrl;
	};

	static sendTechnicalAnalytics = async (analyticsData: TechnicalAnalyticsProps) => {
		try {
			const {event, value} = analyticsData;
			const params = {
				event,
				value,
				key: 'JGMHFqonZuwKeoly+bzNnw==',
			};
			const {data, status} = await axios.post<AnalyticsData>(
				`${AnalyticServices.baseUrl}/stat/event`,
				params
			);
			const checkData = data || 'empty';
			checkResponse(checkData, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send technical analytics error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendAnalytics = async (analyticsData: AnalyticsProps) => {
		try {
			const params = {
				...analyticsData,
				key: 'JGMHFqonZuwKeoly+bzNnw==',
			};
			const {data, status} = await axios.post<AnalyticsData>(
				`${AnalyticServices.baseUrl}/stat`,
				params
			);
			const checkData = data || 'empty';
			checkResponse(checkData, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send analytics error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendAgoraAnalytics = async (
		analyticsData: {
			event: string;
			value: AgoraAnalyticsProps;
		}[]
	) => {
		try {
			const {data, status} = await axios.post(
				`${AnalyticServices.baseUrl}/stat/agora`,
				analyticsData
			);
			const checkData = data || 'empty';
			checkResponse(checkData, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send analytics error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
