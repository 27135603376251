import axios from 'axios';
import {AppTheme} from 'models/app';
import {GreetingMessage} from 'models/room';
import {Settings, SettingsRules} from 'models/settings';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import checkResponse from 'utils/checkResponse';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import Log from 'utils/log';
import {Welcome} from 'models/welcome';
import {GetStickersData, PartnerAuthData} from 'models/responses';

export default class SettingsService {
	static baseUrl = '';

	static setBaseUrl = (baseUrl: string) => {
		SettingsService.baseUrl = baseUrl;
	};

	static getSettings = async (apiKey: string) => {
		try {
			const {data, status} = await axios.get<Settings>(
				`${SettingsService.baseUrl}/settings/?apiKey=${apiKey}`
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get settings error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getSettingsThemes = async (apiKey: string) => {
		try {
			const {data, status} = await axios.get<AppTheme[]>(
				`${SettingsService.baseUrl}/settings/theme/?apiKey=${apiKey}`
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get settings themes error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getSettingsTheme = async (apiKey: string, themeId: number) => {
		try {
			const {data, status} = await axios.get<Settings>(
				`${SettingsService.baseUrl}/settings/theme/${themeId}?apiKey=${apiKey}`
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get settings theme error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getRules = async (token: UUID, lang: string) => {
		try {
			const {data, status} = await axios.get<SettingsRules>(
				`${SettingsService.baseUrl}/rules/${lang}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get rules error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getGreetingMessage = async (token: UUID, lang: string) => {
		try {
			const {data, status} = await axios.get<GreetingMessage>(
				`${SettingsService.baseUrl}/greeting/${lang}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get greeting message error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getWelcome = async (token: UUID, lang: string) => {
		try {
			const {data, status} = await axios.get<Welcome>(
				`${SettingsService.baseUrl}/welcome/${lang}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get Welcome error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getStickers = async (token: UUID) => {
		try {
			const {data, status} = await axios.get<GetStickersData>(
				`${SettingsService.baseUrl}/sticker`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get stickers error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static auth = async (apiKey: string) => {
		try {
			const {data, status} = await axios.get<PartnerAuthData>(
				`${SettingsService.baseUrl}/settings/global/${apiKey}`
			);
			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Auth partner error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
