/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Agreement',
		subTitle: 'License agreement and privacy policy',
		textFirst: 'I accept the terms of the ',
		textSecond:
			'and give my consent to the processing of personal data on the terms defined in the ',
		license: 'License agreement',
		policy: 'Privacy Policy',
	},
	controlPanel: {
		streamEnded: 'Broadcast is over',
		banAlert: `You can't write in the chat`,
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? 'Stickers' : 'Emoji',
	},
	accountDeletedScreen: {
		title: 'Profile deleted',
		text: (date: string) => `You can restore your profile until ${date}`,
	},
	nameScreen: {
		title: 'Nickname',
		inputPlaceholder: 'Enter name',
		nameError: 'Invalid name',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Choose another one:',
	},
	settingsScreen: {
		title: 'Settings',
		personal: 'Personal settings',
		room: 'Room settings',
		profileBtn: 'Profile',
		languageBtn: 'Language',
		blockedBtn: 'Blocked',
		rules: 'Chat rules',
		deleteProfile: 'Delete profile',
		slowModeBtn: 'Slow mode',
		agora: {
			microphone: 'Microphone',
			playbackDevice: 'Output device',
		},
	},
	languageScreen: {
		title: 'Language and interface',
		messagesTranslate: 'Message translation',
		translateMessagesMode: 'Translate chat messages',
		translateMessagesTo: 'Translate chat messages to',
	},
	rulesScreen: {
		title: 'Chat rules',
	},
	profileScreen: {
		title: 'Profile',
		selectAnotherPic: 'Choose another avatar',
		chatNameLabel: 'Nickname',
		changeName: 'сhange',
	},
	blockedScreen: {
		title: 'Blocked users',
		emptyListText: `You haven't blocked any users`,
	},
	chatScreen: {
		inputPlaceholder: 'Message...',
		cantWrite: 'You can&#8217;t write in this chat',
		chatSubmenu: {
			hideMessage: 'Hide messages',
			showMessage: 'Show messages',
			removeFromSpeakers: 'Remove from speakers',
			setSpeaker: 'Turn into speaker',
			hideOnlyMessage: 'Hide message',
			showOnlyMessage: 'Show message',
			reportMessage: 'Report message',
			reportUser: 'Report user',
			muteSpeaker: 'Mute',
			blockUser: 'Block',
			unblockUser: 'Unblock',
			reply: 'Reply',
			copy: 'Copy',
			edit: 'Edit',
			delete: 'Delete',
			pinMessage: 'Pin',
			unpinMessage: 'Unpin',
			goToProfile: 'Go to user profile',
			replyInThread: 'Reply in thread',
		},
		actionMessage: {
			editing: 'Editing',
			reply: 'Reply',
		},
		status: {
			top: 'Top',
		},
		translating: 'Translating...',
	},
	chatUsersScreen: {
		title: 'Participants',
		edited: 'Edited',
		hidden: 'Hidden',
		unreadMessages: 'New messages',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Mute a participant',
				subtitle: 'Turn off the microphone',
			},
			setSpeaker: {
				title: 'Demote from speaker',
				subtitle: 'Text chat only',
			},
			removeFromSpeaker: {
				title: 'Make a speaker',
				subtitle: 'Participant will be able to speak',
			},
			showMessages: {
				title: 'Show messages',
				subtitle: 'Visible to all participants',
			},
			hideMessages: {
				title: 'Hide messages',
				subtitle: 'Visible only to the admin',
			},
			blockUser: {
				title: 'Block',
				subtitle: 'Add to blocklist',
			},
			unblockUser: {
				title: 'Unblock',
				subtitle: 'This user will see your messages',
			},
			blockUserByUser: {
				title: 'Block',
				subtitle: "You won't see each other's messages",
			},
			unblockUserByUser: {
				title: 'Unblock',
				subtitle: 'User will be able to read your messages',
			},
			reportUser: {
				title: 'Report user',
				subtitle: 'Complaints are submitted anonymously',
			},
		},
		days: {
			today: 'Today',
			yesterday: 'Yesterday',
		},
		chatCreated: 'This chat was started',
	},
	alerts: {
		btns: {
			unblock: 'Unblock',
			block: 'Block',
			send: 'Send',
			sendAndBlock: 'Send report, and block user',
			show: 'Show',
			showAll: 'Show all',
			cancel: 'Cancel',
			yes: 'Yes',
			hide: 'Hide selected',
			hideAll: 'Hide all',
			make: 'Make',
			remove: 'Remove',
			delete: 'Delete',
			deleteAccount: 'Delete profile',
			close: 'Close',
			pin: 'Pin',
			unpin: 'Unpin',
			edit: 'Edit',
			end: 'Complete',
			endAndShare: 'Complete and share',
			reset: 'Reset',
			rules: 'Chat rules',
			contactus: 'Text us',
		},
		pinMessage: {
			title: 'Pin this message?',
		},
		unPinMessage: {
			title: 'Unpin this message?',
		},
		accountRestored: {
			title: 'Profile is restored',
		},
		closeApp: {
			title: 'Do you want to quit?',
		},
		removeMessage: {
			title: 'Delete message?',
		},
		report: {
			title: 'Send report?',
			subtitle:
				"We won't notify the user of your report. Also, you can block them to hide each other's messages.",
		},
		banUser: {
			title: (name: string) => `Ban ${name}?`,
			subtitle: (text: string) => `The user will be blocked for ${text}`,
		},
		unBanUser: {
			title: (name: string) => `Unblock ${name}?`,
		},
		showUserMessages: {
			title: (name: string) => `Show messages from ${name}?`,
			subTitle: 'Other participants will be able to see messages from this user.',
		},
		hideUserMessages: {
			title: (name: string) => `Hide messages from ${name}?`,
			subTitle: 'Other participants will no longer be able to see messages from this user.',
		},
		showUserMessage: {
			title: (name: string) => `Show message from ${name}?`,
			subTitle: 'Other users will be able to see this message',
		},
		hideUserMessage: {
			title: (name: string) => `Hide message from ${name}?`,
			subTitle: `Other participants won't be able to see this message.`,
		},
		blockUserInRoom: {
			title: (name: string) => `Block ${name}?`,
			subTitle: `The user won't be able to participate in this room.`,
		},
		unBlockUserInRoom: {
			title: (name: string) => `Unblock ${name}?`,
			subTitle: 'The user will be able to participate in this room.',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `Block ${name}?`,
			subTitle: 'You won&#8217;t see each other&#8217;s messages',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Make ${name} a speaker?`,
			unTitle: (name: string) => `Remove ${name} from speakers?`,
		},
		banInApp: {
			title: 'Admin blocked you',
			for: 'for',
			subtitle: (bannedUntil: string) => `You will be blocked until ${bannedUntil}`,
			permanent: 'You are blocked permanently',
		},
		banInAppBadNickname: {
			title: 'You are blocked for the inappropriate nickname',
			subtitle: 'Open your profile settings, change the nickname, and re-join the chat',
		},
		deleteAccount: {
			title: 'Profile deletion',
			subTitle: 'All data will be deleted. You can restore your profile within 30 days.',
		},
		pollEdit: {
			title: 'Edit the poll?',
			subTitle: `Users' responses will be reset.`,
		},
		pollReset: {
			title: 'Reset?',
			subTitle: 'The poll will not be saved.',
		},
		pollEnd: {
			title: 'Do you want to complete the survey?',
		},
		pollDelete: {
			title: 'Delete the poll?',
		},
		agoraCheck: {
			updateIOSText: 'Update the iOS version to use all the app’s tools.',
			allowAccess: {
				title: 'Do you want to say something?',
				text: 'Please open the app settings and allow access to the microphone and speaker.',
			},
			microphoneAndSpeakerText:
				'Microphone and speaker usage have been available since the 14.3 version.',
			microphoneText: 'Update the iOS version to use all the app’s tools.',
			btn: 'Okay!',
		},
	},
	eventScreen: {
		title: 'Scheduled event',
		titleSoon: 'The broadcast will start soon',
		subtitle: 'The chat will be available soon; please wait.',
		days: 'days',
		hours: 'hours',
		minutes: 'minutes',
		seconds: 'seconds',
		startTitle: 'Starts at',
	},
	toasts: {
		reciveComplaint: 'We have received your complaint',
		setSpeakerRoleForUser: 'User appointed as speaker',
		unSpeakerRoleForUser: 'User muted',
		messagesShown: 'Messages shown',
		messagesHidden: 'Messages hidden',
		messageShown: 'Message shown',
		messageHidden: 'Message hidden',
		userBlocked: 'User blocked',
		userUnBlocked: 'User unblocked',
		youAreSpeaker: 'You are speaker! Say something',
		youAreNotSpeaker: 'You are muted',
		userIsSpeaker: (name: string) => `${name} is speaker now`,
		userNotSpeaker: (name: string) => `${name} is no longer a speaker`,
		yourUserNameInChat: (name: string) => `Your nickname is ${name}`,
		messageRemoved: 'Message deleted',
		messageCopied: 'Text copied',
		obscenities: 'Please check that your message does not violate our community guidelines.',
		requestSent: 'Request sent',
		requestDecline: `You haven't been given the mic yet`,
		youareblocked: (text: string) => `You have been blocked for ${text}. You cannot write messages`,
		youareunblocked: 'You have been unblocked. You can send messages.',
		youAreModerator: 'You are moderator!',
		youAreNoModerator: 'You are no longer a moderator',
		micMuted: 'Your microphone muted',
		noInet: 'No Internet connection',
		messagePinned: 'Message pinned',
		messageUnpinned: 'Message unpinned',
		pollPublished: 'Poll published',
		pollPublishedError: 'Error! Try again later',
		pollAddPic: 'Add a picture',
		pollAddPicError: 'Choose another one',
		pollDeleted: 'Poll deleted',
		pollEnded: 'Poll completed',
		sendStickerWhenSlowModeIsOn: (time: string) => `Wait ${time} before sending a sticker`,
		streamConnecting: 'Connecting to the stream...',
		linkProhibited: 'You cannot send links in the chat',
		phoneProhibited: 'Phone numbers cannot be shared in the chat',
		profileHidden: 'User has hidden their profile',
		imageSizeWarning: 'You can upload an image up to 10 MB',
		imageBlocked: 'The moderator has blocked image uploads',
		streamKeyCopied: 'Broadcast key copied',
	},
	btns: {
		acceptandgo: 'Accept and continue',
		saveandgo: 'Save and continue',
		save: 'Save',
		unblock: 'Unblock',
		block: 'Block',
		restoreAccount: 'Restore profile',
		close: 'Close',
	},
	coopyrights: 'Powered by Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Report the message' : 'Report this user for',
			subtitle: 'Complaints are sent anonymously',
			btns: {
				violence: 'Violence',
				provocations: 'Provocations',
				personalDetails: 'Personal details',
				flood: 'Flood',
				spam: 'Spam',
				fraudBegging: 'Fraud or begging',
				other: 'Other',
			},
		},
		ban: {
			title: 'Block this user',
			subtitle: 'Specify the reason for blocking',
			btns: {
				insults: 'Insults, threats, swearing',
				personal: 'Personal data',
				flood: 'Flood',
				links: 'Links, advertising of third-party services',
				violence: 'Violence',
				fraud: 'Fraud or begging',
				nickname: 'Forbidden nickname',
				politicial: 'Political, sexualised, and other provocations',
				repeated: 'Repeated violation',
				auto: 'Chat user complaints',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'No requests',
			handsUp: 'Requests to speak',
			waiting: 'Pending participants',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Choose a correct answer',
				typeQuizMultiple: 'Check the correct options',
				typePoll: 'Share your opinion',
				typePollMultiple: 'There are several options',
				default: 'Public poll',
			},
			btns: {
				reply: 'Reply',
				vote: 'Vote',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Quiz',
				typePoll: 'A few answers',
				default: 'Public poll',
			},
			btns: {
				edit: 'Edit poll',
				end: 'Complete',
			},
		},
		pollCreated: {
			titles: {
				created: 'Create a poll',
				typeQuiz: 'Quiz',
				typePoll: 'A few answers',
				default: 'Public poll',
			},
		},
		changeName: {
			title: 'Change your nickname',
			subtitle: 'You are blocked for an invalid nickname',
		},
		temporaryAgreement: {
			title: 'Read the chat rules',
			rules: {
				bePolite: {
					title: 'Be polite',
					text: "Don't send hostile and obscene messages.",
				},
				beAttentive: {
					title: 'Be attentive',
					text: "Don't share personal data, and don't ask others for it.",
				},
				notAdvertise: {
					title: "Don't sell anything",
					text: 'Share your opinion and emotions instead.',
				},
			},
			btns: {
				allRules: 'Read all the rules',
				acceptAndContinue: 'Got it!',
			},
		},
		slowMode: {
			title: 'Slow Mode',
			text: "Participants won't be able to send messages in the chat more frequently than the chosen time interval.",
			enable: 'Enable slow mode',
			disable: 'Disable slow mode',
			sendTimeout: 'Set timeout',
		},
		slowModeTimeout: {
			title: 'Set timeout',
		},
		chatBot: {
			title: 'Chatbot usage',
			rules: [
				{
					title: 'Artificial Intelligence',
					text: 'The bot mimics human communication and provides information by virtue of its knowledge. Remember, however, that its responses may be limited and will not replace humans.',
				},
				{
					title: 'The bot may make mistakes',
					text: 'We cannot guarantee the validity and accuracy of the information provided by the bot.',
				},
			],
			btns: {
				close: 'Close',
			},
		},
		shareBet: {
			title: 'Share your bet',
			noBetsTitle: "You don't have any bets to share",
			noBetsText: 'You can share the bets placed in the last 14 days.',
			btns: {
				share: 'Share bet',
				copy: 'Copy bet',
			},
			status: {
				null: 'Pending',
				win: 'Won',
				lose: 'Lost',
			},
			betTypeV1: {
				express: 'EXPRESS',
				single: 'SINGLE',
			},
			betTypeV2: {
				single: 'Single',
				multi: 'Multi',
				system: 'System',
			},
			totalFactor: 'Odd',
			bet: 'bet',
			showMore: 'Show more',
			betRepeated: 'Copied bet',
			times: ['time', 'times', 'times'],
		},
		shareGamble: {
			noGamblesText:
				'You have no winnings to share yet. Keep playing, and your next win will appear here to share with other players!',
		},
		record: {
			text: 'The recording is being processed...',
		},
		agoraDevices: {
			title: 'Audio settings',
			text: 'Check if the correct microphone is selected.',
		},
		translate: {
			title: 'Translation of messages',
			chooseLanguage: 'Select language',
			descr: 'Messages will be translated automatically',
			btn: 'Translate',
		},
		streamSettings: {
			title: 'Stream settings',
			description: 'Install a video encoder and enter the stream key in its settings.',
			shareScreen: {
				title: 'Screen sharing',
				description: 'No special settings required',
			},
			rtmp: {
				title: 'External stream source',
				description: 'Use additional software to stream the feed.',
			},
			streamKey: 'Stream key',
			showStreamKey: 'Show',
			hideStreamKey: 'Hide',
			btns: {
				continue: 'Continue',
				close: 'Close',
				ok: 'Done',
			},
		},
	},
	pinnedMessage: {
		title: 'Pinned message',
	},
	errorPage: {
		error: 'Error',
		types: [
			// errorNumber 0
			{
				title: 'We do not recognise you',
				text: 'Try re-entering the room.',
			},
			// errorNumber 1
			{
				title: `We can't find the room`,
				text: 'Try to re-enter',
			},
			// errorNumber 2
			{
				title: 'Unable to load the room',
				text: 'Please restart and try again.',
			},
			// errorNumber 3
			{
				title: 'We do not recognise you',
				text: 'The room is not available in incognito mode.',
			},
			// errorNumber 4
			{
				title: 'Invalid nickname',
				text: 'Please change your nickname in the profile settings.',
			},
		],
	},
	tooltips: {
		emotions: 'Hold to change',
		badInet: 'Poor network connection',
		blockedUsers: 'Check your blocked users list',
		waitingSpeakers: 'Someone wants to speak.',
		wantSpeak: 'Raise hand if you want to speak.',
		slowMode: {
			header: (time: string) =>
				`Slow mode is on. Users can <br>send one message every ${time}, <br>but you are immune.`,
			btnSendMessage: (time: string) => `You can send a message every ${time}.`,
		},
		shareBet: 'Share a bet in the chat',
		translate: 'Translate to a needed language',
	},
	poll: {
		toggleSubmenu: {
			create: 'Create a poll',
			edit: 'Edit',
			show: 'View',
			end: 'Complete',
		},
		tooltip: {
			btn: 'Vote',
		},
		settings: {
			title: 'Settings',
			titleAdditionally: 'More',
			switches: {
				quizMode: 'Quiz mode',
				multipleChoice: 'Multiple choice of answers',
				withPhoto: 'Poll with pictures',
			},
		},
		question: {
			title: 'Question',
			inputPlaceholder: 'Enter a question',
		},
		message: {
			title: 'You voted',
		},
		answers: {
			title: 'Answer options',
			warning: 'You can add up to 5 answers',
		},
		answer: {
			tooltip: 'Choose a correct answer',
			inputPlaceholder: 'Answer',
		},
		results: {
			text: 'Results',
			votes: 'Votes',
			// words: ['vote', 'votes', 'votes'],
		},
		btns: {
			addQuestion: 'Add question',
			addAnswers: 'Add answers',
			addAnswer: 'Add answer',
			publish: 'Publish',
			vote: 'Vote',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Rate',
				continue: 'Next',
				checkConnection: 'Test your connection',
				close: 'Exit',
			},
			quality: {
				title: 'Please, rate the sound quality',
			},
			reason: {
				title: 'What was wrong?',
			},
			connection: {
				title: 'Test your connection, please',
				text: 'It will last up to a minute and help us to find the cause of the problem.',
			},
			statistics: {
				title: 'Running tests...',
			},
			done: {
				title: 'Thank you!',
				text: "We'll investigate the issue and fix it.",
			},
		},
	},
	chatBot: {
		message: 'Ask AI bot anything...',
		warnings: {
			disabled: "I've been disconnected",
			overloaded: "We're overloaded",
		},
	},
	audioReason: {
		nothear: `I couldn't hear anything`,
		connection: 'The sound was choppy',
		microphone: `Microphone didn't work`,
	},

	copybetting: {
		orderStatus: {
			won: 'Won',
			lost: 'Lost',
			new: 'New',
			pending: 'Pending',
			cashedOut: 'Cashed out',
			boreDraw: 'Bore draw',
			cashback: 'Cashback',
			megaBetNew: 'MegaBet New',
			megaBetCanceledByOperator: 'MegaBet Canceled By Operator',
			megaBetCanceledByPlayer: 'MegaBet Canceled By Player',
			megaBetCanceledBySystem: 'MegaBet Canceled By System',
			betGamesPending: 'BetGames Pending',
			betGamesWon: 'BetGames Won',
			betGamesLost: 'BetGames Lost',
			paid: 'Paid',
			initialWin: 'Initial Win',
			autoBetCanceledBySystem: 'AutoBet Canceled By System',
			autoBetCanceledByPlayer: 'AutoBet Canceled By Player',
			autobetPending: 'Autobet Pending',
			goalAhead: 'Goal Ahead',
			canceled: 'Canceled',
			return: 'Return',
		},
		stakeStatus: {
			pending: 'Pending',
			won: 'Won',
			lost: 'Lost',
			return: 'Return',
			lostReturn: 'Lost | Return',
			wonReturn: 'Won | Return',
			canceled: 'Canceled',
			initialReturn: 'Initial Return',
			pendingInitialReturn: 'Pending | Initial Return',
			wonInitialReturn: 'Won | Initial Return',
		},
	},
	screenShare: {
		title: 'Screen Sharing',
		stopSharing: 'Stop sharing',
		modal: {
			title: 'Screen sharing is only available on desktop',
			text: `Open the chat on your computer and click 'Screen Sharing' in the top right corner.`,
			btn: 'Copy the invite link',
		},
	},
	threads: {
		title: 'Thread',
		back: 'Back',
		replies: ['reply', 'replies', 'replies'],
	},
	attach: {
		submenu: {
			sendImage: 'Send image',
			shareBet: 'Share bet',
			shareGamble: 'Share win',
		},
		images: ['image', 'images', 'images'],
		addCaption: 'Add caption',
	},
	following: {
		following: 'Following',
	},
} as unknown as Dictionary;
