/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {START_NUMBER_OF_MESSAGES, MAXIMUM_NUMBER_OF_MESSAGES} from 'constants/constants';
import {generateErrorResponseObj, generateResponseObj} from 'utils/generateResponseObj';
import axios from 'axios';
import {
	RegisterResponseData,
	GetBlockedUsersData,
	BlockedUserData,
	DeleteBlockedUserData,
	GetMessagesData,
	GetAllDataOnLoad,
	GetStickersData,
	GetUserLastMessageData,
} from 'models/responses';
import ResponseStatus from 'models/enums/ResponseStatus.enum';
import Log from 'utils/log';
import checkResponse from 'utils/checkResponse';
import {PollCreated, PollCreatedOption} from 'models/poll';

export default class RoomService {
	static getRoomData = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(`/room/${externalRoomId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get room data error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getBlockedUsers = async (token: UUID) => {
		try {
			const {data, status} = await axios.get<GetBlockedUsersData>(`/block`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get blocked users error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static blockUser = async (targetId: number, token: UUID) => {
		try {
			const params = {
				targetId,
			};

			const {data, status} = await axios.post<BlockedUserData>(`/block`, params, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Block user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static deleteBlockedUser = async (targetId: number, token: UUID) => {
		try {
			const params = {
				targetId,
			};

			const {data, status} = await axios.delete<DeleteBlockedUserData>(`/block`, {
				headers: {Authorization: `Bearer ${token}`},
				data: params,
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Delete blocked user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static reportUser = async (messageId: number, reason: string, token: UUID) => {
		try {
			const params = {
				messageId,
				reason,
			};

			const {data, status} = await axios.post<RegisterResponseData>(`/report`, params, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Report user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getDataOnLoad = async (
		token: UUID,
		externalRoomId: string,
		limit?: number,
		onlySubscriptions?: number
	) => {
		try {
			let apiUrl = `/data/${externalRoomId}`;

			apiUrl = `${apiUrl}?limit=${limit}`;
			if (onlySubscriptions) apiUrl = `${apiUrl}&onlySubscriptions=${onlySubscriptions}`;

			const {data, status} = await axios.get<GetAllDataOnLoad>(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get messages error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessages = async (
		token: UUID,
		externalRoomId: string,
		limit?: number,
		firstId?: number,
		lastId?: number,
		onlySubscriptions?: number
	) => {
		try {
			const creationApiUrl = () => {
				let apiUrl = `/message/room/${externalRoomId}`;
				apiUrl = `${apiUrl}?limit=${limit || START_NUMBER_OF_MESSAGES}`;

				if (typeof firstId !== 'undefined') {
					apiUrl = `${apiUrl}&firstId=${firstId}`;
				}

				if (lastId && typeof lastId !== 'undefined') {
					apiUrl = `${apiUrl}&lastId=${lastId}`;
				}

				if (onlySubscriptions !== undefined)
					apiUrl = `${apiUrl}&onlySubscriptions=${onlySubscriptions}`;

				return apiUrl;
			};

			const {data, status} = await axios.get<GetMessagesData>(creationApiUrl(), {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getMessages users error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getMessagesAround = async (
		token: UUID,
		externalRoomId: string,
		messageId: number,
		limit?: number,
		onlySubscriptions?: number
	) => {
		try {
			let apiUrl = `/message/room/${externalRoomId}/around?limit=${
				limit || MAXIMUM_NUMBER_OF_MESSAGES
			}&messageId=${messageId}`;

			if (onlySubscriptions) apiUrl = `${apiUrl}&onlySubscriptions=${onlySubscriptions}`;

			const {data, status} = await axios.get<GetMessagesData>(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get messages around error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPinnedMessages = async (token: UUID, externalRoomId: string) => {
		try {
			const apiUrl = `/message/room/${externalRoomId}/pinned`;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse({}, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get pinned messages error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getUserLastMessage = async (token: UUID, externalRoomId: string, userId: number) => {
		try {
			const apiUrl = `/message/last/room/${externalRoomId}/user/${userId}`;
			const {data, status} = await axios.get<GetUserLastMessageData>(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Get user last message error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getTalkersCount = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(`talker/room/${externalRoomId}/count`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getTalkersCount user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getTalkers = async (
		externalRoomId: string,
		token: UUID,
		limit: number,
		offset: number,
		onlySubscriptions?: number
	) => {
		try {
			let apiUrl = `/talker/?isActive=1&room__externalRoomId=${externalRoomId}&limit=${limit}&offset=${offset}`;
			if (onlySubscriptions) apiUrl = `${apiUrl}&onlySubscriptions=${onlySubscriptions}`;

			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getTalkers user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getSpeakers = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`/talker/?isActive=1&role=SPEAKER&room__externalRoomId=${externalRoomId}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getSpeakers user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getBannedTalkers = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(`/talker/room/${externalRoomId}/ban`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getBannedTalkers user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getWaitingSpeakers = async (externalRoomId: string, token: UUID) => {
		try {
			const {data, status} = await axios.get(
				`/talker/?isActive=1&hand=1&room__externalRoomId=${externalRoomId}`,
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getWaitingSpeakers user error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getPoll = async (token: UUID, externalRoomId: string) => {
		try {
			const apiUrl = `/poll/${externalRoomId}`;
			const {data, status} = await axios.get(apiUrl, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse({}, status); // hack -_-

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('getPoll error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static setPoll = async (
		pollCreateData: PollCreated,
		token: UUID,
		pollWithPics: {value: boolean}
	) => {
		try {
			let elementsWoLocalId: any = pollCreateData;
			if (pollCreateData.options) {
				elementsWoLocalId = pollCreateData.options.map((el: PollCreatedOption) => {
					if (!pollWithPics.value) {
						const {id, pic, ...elRest} = el;
						return elRest;
					}
					const {id, ...elRest} = el;
					return elRest;
				});
			}

			const {data, status} = await axios.post(
				`/poll`,
				{...pollCreateData, options: elementsWoLocalId},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Set vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static uploadPollOptionPicture = async (FormData: FormData, token: UUID) => {
		try {
			const {data, status} = await axios.post(`/room/pic`, FormData, {
				headers: {Authorization: `Bearer ${token}`, 'content-type': 'multipart/form-data'},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('uploadPollOptionPicture error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendPollVote = async (externalRoomId: string, optionIds: number[], token: UUID) => {
		try {
			const params = {
				optionIds,
			};

			const {data, status} = await axios.post(`/poll/${externalRoomId}/vote`, params, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static endPoll = async (externalRoomId: string, token: UUID, share?: boolean) => {
		try {
			let apiPathEndPoll = `/poll/${externalRoomId}/end`;

			if (typeof share !== 'undefined') {
				apiPathEndPoll = `${apiPathEndPoll}/?share=${share ? 1 : 0}`;
			}

			const {data, status} = await axios.get(apiPathEndPoll, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('End poll error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static removePoll = async (externalRoomId: string, pollId: number, token: UUID) => {
		try {
			const {data, status} = await axios.delete(`/poll/${externalRoomId}/${pollId}`, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Delete poll error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendViewAdvertisement = async (advertisementId: number, token: UUID) => {
		try {
			const {data, status} = await axios.post(
				`/message/advertisement/view/${advertisementId}`,
				{},
				{
					headers: {Authorization: `Bearer ${token}`},
				}
			);

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static sendPicMessage = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(`/message`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};

	static getStreamKey = async (token: UUID, body: any) => {
		try {
			const {data, status} = await axios.post(`/stream/key`, body, {
				headers: {Authorization: `Bearer ${token}`},
			});

			checkResponse(data, status);

			return generateResponseObj(ResponseStatus.SUCCESS, data);
		} catch (error) {
			if (axios.isAxiosError(error)) {
				checkResponse(error.response?.data, error.request.status);
			}
			Log.error('Send vote error: ', error);

			return generateErrorResponseObj(error instanceof Error ? error.message : null);
		}
	};
}
