/* eslint-disable max-lines */
import {times} from 'data/slowModeData';
import {findValueByKeyInObject} from 'utils/findValueByKeyInObject';
import LanguageTag from 'models/enums/LanguageTag.enum';
import RoomStatus from 'models/enums/RoomStatus.enum';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import TooltipType from 'models/enums/TooltipType.enum';
import NetworkQuality from 'models/enums/NetworkQuality.enum';
import {AlertBtnType} from 'models/enums/Alert.enum';
import {Tooltip as TooltipModel} from 'models/tooltip';

import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import modalServices from 'store/modalService';
import alertService from 'store/alertService';
import tooltipsServices from 'store/tooltipsService';
import slowModeServices from 'store/slowModeService';
import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import useAnalytics from 'hooks/useAnalytics';
import useOnboarding from 'hooks/useOnboarding';
import useAppData from 'hooks/useAppData';
import {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isAndroid, isDesktop, isMobile} from 'react-device-detect';
import classNames from 'classnames';
import Button from 'components/hoc/Button';
import Tooltip from 'components/tooltip/Tooltip';
import {Users} from 'components/users';
import {ShareDropdown} from 'components/shareDropdown';
import {ReactComponent as IcoShareDesktop} from 'assets/svg/ico-share-desktop.svg';
import {ReactComponent as IcoShareAndroid} from 'assets/svg/ico-share-android.svg';
import {ReactComponent as IcoShareIos} from 'assets/svg/ico-share-ios.svg';
import './header.scss';
import useUser from 'hooks/useUser';
import useSearchParams from 'hooks/useSearchParams';
import useTranslation from 'hooks/useTranslation';
import UserRole from 'models/enums/UserRole.enum';
import agoraService from 'store/agoraService';
import threadService from 'store/threadService';
import actionMessageService from 'store/actionMessageService';
import controlPanelService from 'store/controlPanelService';
import {Switcher} from 'components/switcher';

const TIMEOUT_TRANSLATE_TOOLTIP = 15000;
const TIMEOUT_TRANSLATE_TOOLTIP2 = 5000;

const Header: FunctionComponent = function Header() {
	const timeoutBlockedUsersToolTipVisibilityRef: {current: NodeJS.Timeout | null} = useRef(null);
	const timeoutTranslateToolTipVisibilityRef: {current: NodeJS.Timeout | null} = useRef(null);
	const referenceNoInet = useRef<HTMLButtonElement>(null);
	const referenceSettings = useRef<HTMLDivElement>(null);
	const referenceSlowMode = useRef<HTMLDivElement>(null);
	const referenceTranslate = useRef<HTMLDivElement>(null);
	const tooltipRef: {current: TooltipModel | null} = useRef(null);
	const [noInetTooltip, setNoInetTooltip] = useState(false);
	const [slowModeTooltip, setSlowModeTooltip] = useState(false);
	const [translateTooltip, setTranslateTooltip] = useState(false);
	const [headerTransparent, setHeaderTransparent] = useState(false);
	const [blockedUsersToolTipVisibility, setBlockedUsersToolTipVisibility] = useState(false);
	const [isShareDropDownHovered, setIsShareDropDownHovered] = useState(false);
	const {
		project,
		appReadOnly,
		language,
		isInternernetActive,
		shareData,
		networkQuality,
		blockedUsers,
		appIcons,
		appExitButtonVisible,
		appSettingsButtonVisible,
		appUserCounterButtonVisible,
		appShowAudioQualityCheckPopup,
		appEnableOnboarding,
		appShowShareButton,
		appShowAddToCalendarButton,
		appVersion,
		appEnableMessageTranslation,
		translateLangs,
		enableScreenSharing,
		enableRoomSpeak,
		appLowerUsersModule,
		appEnableSubscriptionsFilter,
		isStand,
		appEnableFullscreenOption,
		appFullscreenButtonVisible,
	} = useLocalObservable(() => appService);
	const {
		userData,
		isUserExternalIdCorrect,
		clearUserData,
		translateMode,
		setTranslateMode,
		accessToken,
	} = useLocalObservable(() => userServices);
	const {roomData, myTalker, pinnedMessages, isFilterBySubscription, subscriptions} =
		useLocalObservable(() => roomServices);
	const {
		hideAllModals,
		showSettingsModal,
		toggleFeedbackAudioModal,
		agreementAndChatRulesModal,
		toggleTranslateModalVisible,

		visibleThreadModal,
		toggleVisibleThreadModal,
		toggleStreamSettingsModalVisible,
		setScreenShareWarnModalVisible,
	} = useLocalObservable(() => modalServices);
	const {showAlert, hideAlert} = useLocalObservable(() => alertService);
	const {tooltip, toggleTooltip, translateTooltipVisible, setTranslateTooltipVisible} =
		useLocalObservable(() => tooltipsServices);
	const {slowMode} = useLocalObservable(() => slowModeServices);
	const {isScreenSharing, isMyTalkerShareScreen, isFullScreenVideo} = useLocalObservable(
		() => agoraService
	);
	const {currentThreadId, setCurrentThreadId, clearTread} = useLocalObservable(() => threadService);
	const {isVisibleActionMessage, toggleIsVisibleActionMessage} = useLocalObservable(
		() => actionMessageService
	);
	const {toggleVisibleButtons} = useLocalObservable(() => controlPanelService);
	const {alerts, toasts, tooltips, screenShare, threads} = useL10n();
	const {onBoardingStageHandler} = useOnboarding();
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();
	const {userExtraDataCheckTooltip, userExtraDataPatchTooltip, userExtraDataPatchTranslateMode} =
		useUser();
	const {osFromUrl} = useSearchParams();
	const {translateMessages, getTranslateLangs} = useTranslation();

	const [isFullscreen, setIsFullscreen] = useState(false);

	const {
		IcoLeave,
		IcoSettings,
		IcoNoinet,
		IcoBadinet,
		IcoSlowmode,
		IcoShareRoom,
		IcoCalendar,
		IcoTranslateOn,
		IcoTranslateOff,
		IcoScreenShare,
		IcoChevron,
		IcoFollowing,
		IcoExpand,
		IcoCollapse,
	} = appIcons;

	const navigatorCanShare = !!navigator.canShare;
	const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;

	const checkUserIsOnboarding =
		!appEnableOnboarding || (userData?.isOnboard && appEnableOnboarding);

	const isScreenShareBtn =
		(roomData?.isSpeak || enableRoomSpeak) &&
		enableScreenSharing &&
		roomData?.status === RoomStatus.LIVE &&
		myTalker?.role === UserRole.SPEAKER &&
		!isMyTalkerShareScreen &&
		!isScreenSharing &&
		((!appEnableSubscriptionsFilter && isMobile) || !isMobile);

	const isSlowModeBtn =
		isMyTalkerModer &&
		!agreementAndChatRulesModal &&
		(slowMode.global.enable || slowMode.local.enable) &&
		((!appEnableSubscriptionsFilter && isMobile) || !isMobile);

	const isFullScreenMode = appEnableFullscreenOption && isFullScreenVideo && isDesktop;

	const isShareRoomButton = () => {
		if (!project.includes('tinkoff')) {
			return appShowShareButton;
		}

		// tinkoff ios & app version >= 6.19.0

		if (osFromUrl && osFromUrl.toLowerCase() === 'ios') {
			if (
				(appVersion && appVersion[0] > 6) ||
				(appVersion && appVersion[0] === 6 && appVersion[1] >= 19)
			) {
				return appShowShareButton;
			}
			return false;
		}

		// tinkoff app version >= 6.18.0

		if (
			(appVersion && appVersion[0] > 6) ||
			(appVersion && appVersion[0] === 6 && appVersion[1] >= 18)
		) {
			return appShowShareButton;
		}
		return false;
	};

	const isCalendarButton = () => {
		if (!project.includes('tinkoff')) {
			return appShowAddToCalendarButton && roomData?.status === RoomStatus.SOON;
		}

		// tinkoff ios & app version >= 6.19.0

		if (osFromUrl && osFromUrl.toLowerCase() === 'ios') {
			if (
				(appVersion && appVersion[0] > 6) ||
				(appVersion && appVersion[0] === 6 && appVersion[1] >= 19)
			) {
				return appShowAddToCalendarButton && roomData?.status === RoomStatus.SOON;
			}
			return false;
		}

		// tinkoff app version >= 6.18.0

		if (
			(appVersion && appVersion[0] > 6) ||
			(appVersion && appVersion[0] === 6 && appVersion[1] >= 18)
		) {
			return appShowAddToCalendarButton && roomData?.status === RoomStatus.SOON;
		}
		return false;
	};

	const headerClasses = classNames('header', {
		'header--transparent': headerTransparent && !currentThreadId,
		'header--down':
			pinnedMessages?.length &&
			headerTransparent &&
			!agreementAndChatRulesModal &&
			!visibleThreadModal,
		'header--soon': roomData?.status === RoomStatus.SOON && !isMyTalkerModer,
		'header--withShare': isShareRoomButton() && !appSettingsButtonVisible,
		'header--rulesModalOpened': agreementAndChatRulesModal,
		'header--thread': visibleThreadModal,
		'header--lower': appLowerUsersModule,
	});

	const headerThreadClasses = classNames('header__thread', {
		'header__thread--fullscreen-mode': isFullScreenMode,
	});

	const headerNoinetWrapperClasses = classNames('header__noinet-wrapper', {
		'header__noinet-wrapper--active':
			!isInternernetActive || (isInternernetActive && networkQuality === NetworkQuality.POOR),
	});

	const checkForBlockedUsersTooltip = () => {
		return (
			!isMyTalkerModer &&
			blockedUsers.length &&
			(!tooltipRef.current ||
				!tooltipRef.current.shown ||
				(tooltipRef.current &&
					tooltipRef.current.shown &&
					tooltipRef.current.type === TooltipType.INFORMATIONAL))
		);
	};

	const checkShownTooltipTypeTriggered = () => {
		return (
			tooltipRef.current &&
			tooltipRef.current.shown &&
			tooltipRef.current.type === TooltipType.TRIGGER
		);
	};

	const textForSlowModeTooltip = () => {
		let findtTime = times.find(item => item.time === slowMode.local.time);
		if (slowMode.global.enable && !slowMode.local.enable) {
			findtTime = times.find(item => item.time === slowMode.global.time);
		}
		if (findtTime) {
			const findTitle = findValueByKeyInObject<{default: string; prefix?: string}>(
				findtTime.title,
				language,
				{default: findtTime.title.ru.default}
			);
			return tooltips.slowMode.header(
				language === LanguageTag.ru
					? findTitle.prefix || `ые ${findTitle.default}`
					: findTitle.default
			);
		}
		return '';
	};

	const onExitHandler = () => {
		if (appShowAudioQualityCheckPopup) {
			if (isStand) {
				showAlert({
					title: alerts.closeApp.title,
					buttons: [
						{
							text: alerts.btns.yes,
							type: AlertBtnType.DESTRUCTIVE,
							onPress: async () => {
								localStorage.clear();

								SocketIoServices.soketDisconnect();
								clearUserData();
								hideAllModals();
								hideAlert();
								window.parent.postMessage({watchersWindowClose: true}, '*');
							},
						},
						{
							text: alerts.btns.cancel,
							type: AlertBtnType.NORMAL,
							onPress: hideAlert,
						},
					],
					closeOnBackdropPress: true,
				});
				return;
			}

			if (!appReadOnly && isUserExternalIdCorrect) {
				toggleFeedbackAudioModal(true);
			}
			return;
		}

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify({type: 'watchersWindowClose'}));
			return;
		}

		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(
				JSON.stringify({type: 'watchersWindowClose'})
			);
			return;
		}

		window.parent.postMessage({type: 'watchersWindowClose'}, '*');
	};

	const onFullscreenHandler = () => {
		const msg = {
			type: 'fullscreen',
			body: {
				action: isFullscreen ? 'collapse' : 'expand',
			},
		};

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
			return;
		}
		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
			return;
		}

		window.parent.postMessage(msg, '*');

		setIsFullscreen(!isFullscreen);
	};

	const onMobileShare = async () => {
		try {
			await navigator.share({
				url: shareData.url,
				text: shareData.title,
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error);
		}
	};

	const onShareMouseEnter = () => {
		setIsShareDropDownHovered(true);
	};

	const renderShare = useCallback(() => {
		if (shareData.url && isDesktop) {
			return (
				<div className='header__share' onMouseEnter={onShareMouseEnter}>
					<div className='header__share-content'>
						<button className='header__share-button' type='button'>
							<IcoShareDesktop />
							Invite
						</button>
						<ShareDropdown isShareDropDownHovered={isShareDropDownHovered} />
					</div>
				</div>
			);
		}
		if (isMobile && navigatorCanShare && shareData.url) {
			return (
				<div className='header__share'>
					<div className='header__share-content'>
						<button
							type='button'
							className='header__share-button header__share-button--mobile'
							onClick={onMobileShare}>
							{isAndroid ? <IcoShareAndroid /> : <IcoShareIos />}
							Share
						</button>
					</div>
				</div>
			);
		}
		return null;
	}, [shareData, isShareDropDownHovered]);

	const onSettingsHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			sendAnalytics('settings_opened');
			showSettingsModal();
		}
		if (appEnableMessageTranslation && !translateLangs.length) getTranslateLangs(language);
	};

	const onShareClickHandler = (action: string) => {
		const msg = {
			type: 'share',
			body: {action, data: {...roomData}},
		};

		if ((window as any).webkit?.messageHandlers) {
			(window as any).webkit.messageHandlers.WatchersChannel?.postMessage(JSON.stringify(msg));
			return;
		}

		if (typeof WatchersChannel !== 'undefined') {
			WatchersChannel.postMessage(JSON.stringify(msg));
			return;
		}

		window.parent.postMessage(msg, '*');
	};

	const translateBtnClickHandler = () => {
		if (!translateMode.enable && translateMode.lang.languageCode)
			translateMessages(translateMode.lang.languageCode);
		if (!translateMode.lang.languageCode) {
			toggleTranslateModalVisible(true);
			return;
		}
		setTranslateMode({enable: !translateMode.enable});
		if (userData)
			userExtraDataPatchTranslateMode(userData, {...translateMode, enable: !translateMode.enable});
	};

	const openStreamSettingsModal = () => {
		if (!isMobile) {
			toggleStreamSettingsModalVisible(true);
			return;
		}
		setScreenShareWarnModalVisible(true);
	};

	const onCloseThreadHandler = () => {
		if (isVisibleActionMessage) toggleIsVisibleActionMessage(false);

		toggleVisibleThreadModal(false);
		setCurrentThreadId(null);
		clearTread();
		if (!myTalker?.bans?.length && !myTalker?.user?.bans?.length) toggleVisibleButtons(true);

		if (currentThreadId && currentThreadId !== 'new') SocketIoServices.emitLeave(currentThreadId);
	};

	const onChangeFollowingUsersFilter = async () => {
		roomServices.toggleIsFilterBySubscription(!isFilterBySubscription);
		threadService.toggleIsFilterBySubscription(!isFilterBySubscription);
	};

	useEffect(() => {
		const cssVarPanelTopTransparent = getComputedStyle(document.documentElement).getPropertyValue(
			'--panel-top-transparent'
		);

		if (cssVarPanelTopTransparent && JSON.parse(cssVarPanelTopTransparent)) {
			setHeaderTransparent(true);
		}

		return () => {
			if (timeoutBlockedUsersToolTipVisibilityRef.current) {
				clearTimeout(timeoutBlockedUsersToolTipVisibilityRef.current);
			}
		};
	}, []);

	useEffect(() => {
		const handleTranslateTooltip = () => {
			if (
				translateTooltipVisible &&
				userData &&
				!userExtraDataCheckTooltip(userData, 'translate')
			) {
				setTranslateTooltip(true);
				if (!tooltipRef.current?.shown) {
					toggleTooltip({shown: true, type: TooltipType.TRIGGER});
				}
				userExtraDataPatchTooltip(userData, 'translate');
			}
		};

		const handleTimeout = () => {
			timeoutTranslateToolTipVisibilityRef.current = setTimeout(
				handleTranslateTooltip,
				userData && !userExtraDataCheckTooltip(userData, 'emotion')
					? TIMEOUT_TRANSLATE_TOOLTIP
					: TIMEOUT_TRANSLATE_TOOLTIP2
			);
		};

		handleTimeout();

		return () => {
			if (timeoutTranslateToolTipVisibilityRef.current)
				clearTimeout(timeoutTranslateToolTipVisibilityRef.current);
		};
	}, [accessToken, userData, tooltipRef.current, translateTooltipVisible]);

	useEffect(() => {
		if (checkForBlockedUsersTooltip()) {
			timeoutBlockedUsersToolTipVisibilityRef.current = setTimeout(() => {
				if (
					checkForBlockedUsersTooltip() &&
					userData &&
					!userExtraDataCheckTooltip(userData, 'blockedUsers')
				) {
					setBlockedUsersToolTipVisibility(true);
					if (!tooltipRef.current?.shown) {
						toggleTooltip({shown: true, type: TooltipType.TRIGGER});
					}
					userExtraDataPatchTooltip(userData, 'blockedUsers');
				}
			}, 3000);
		}
	}, [userData, myTalker, blockedUsers.length, tooltipRef.current]);

	useEffect(() => {
		if (isInternernetActive && networkQuality === NetworkQuality.POOR) {
			sendAnalytics('bad_connection');
			userData?.id && SocketIoServices.emitBadConnection(userData?.id);
			return;
		}
		userData?.id && SocketIoServices.emitNormalConnection(userData?.id);
	}, [isInternernetActive, networkQuality]);

	return (
		<div className={headerClasses}>
			{currentThreadId && (
				<div className={headerThreadClasses}>
					<div className='header__left'>
						<Button className='header__close-thread' onClick={onCloseThreadHandler}>
							<span className='header__close-thread-arrow'>{getAppIcon(IcoChevron.pic)}</span>
							{threads.back}
						</Button>
					</div>
					<p className='header__thread-title'>{threads.title}</p>
					{!appLowerUsersModule && !isFullScreenMode && (
						<div className='header__right'>
							<div className={headerNoinetWrapperClasses}>
								<Button
									className='header__noinet-btn'
									workWithoutInet
									innerRef={referenceNoInet}
									onClick={() => setNoInetTooltip(!noInetTooltip)}>
									<span
										className={`header__noinet-btn-icon ${
											isInternernetActive && 'header__noinet-btn-icon--active'
										}`}>
										{getAppIcon(IcoBadinet.pic)}
									</span>
									<span
										className={`header__noinet-btn-icon ${
											!isInternernetActive && 'header__noinet-btn-icon--active'
										}`}>
										{getAppIcon(IcoNoinet.pic)}
									</span>
								</Button>

								{noInetTooltip && (
									<Tooltip
										referenceElement={referenceNoInet.current}
										position={TooltipPositoinType.BOTTOM}
										text={!isInternernetActive ? toasts.noInet : tooltips.badInet}
										delay={3000}
										callbackOnHide={() => {
											setNoInetTooltip(false);
											if (tooltip?.shown) {
												toggleTooltip(null);
											}
										}}
									/>
								)}
							</div>
							{isSlowModeBtn && (
								<div className='header__slowmode-wrapper' ref={referenceSlowMode}>
									<Button
										className='header__slowmode-btn'
										onClick={() => setSlowModeTooltip(!slowModeTooltip)}>
										{getAppIcon(IcoSlowmode.pic)}
									</Button>

									{slowModeTooltip && textForSlowModeTooltip() && (
										<Tooltip
											referenceElement={referenceSlowMode.current}
											position={TooltipPositoinType.BOTTOM}
											text={textForSlowModeTooltip()}
											delay={3000}
											callbackOnHide={() => setSlowModeTooltip(false)}
										/>
									)}
								</div>
							)}
							{appEnableMessageTranslation && !agreementAndChatRulesModal && (
								<div className='header__translate-wrapper'>
									<Button
										className='header__translate-btn'
										onClick={
											checkUserIsOnboarding ? translateBtnClickHandler : onBoardingStageHandler
										}>
										{!translateMode.enable
											? getAppIcon(IcoTranslateOn.pic)
											: getAppIcon(IcoTranslateOff.pic)}
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
			)}
			<div className='header__left'>
				{appExitButtonVisible && (
					<Button className='header__exit-btn' onClick={onExitHandler}>
						{getAppIcon(IcoLeave.pic)}
					</Button>
				)}
				{appFullscreenButtonVisible && (
					<Button className='header__exit-btn' onClick={onFullscreenHandler}>
						{!isFullscreen && getAppIcon(IcoExpand.pic)}
						{isFullscreen && getAppIcon(IcoCollapse.pic)}
					</Button>
				)}
			</div>

			{!currentThreadId && (
				<div className='header__right'>
					{(!currentThreadId || (currentThreadId && appLowerUsersModule)) && (
						<div className={headerNoinetWrapperClasses}>
							<Button
								className='header__noinet-btn'
								workWithoutInet
								innerRef={referenceNoInet}
								onClick={() => setNoInetTooltip(!noInetTooltip)}>
								<span
									className={`header__noinet-btn-icon ${
										isInternernetActive && 'header__noinet-btn-icon--active'
									}`}>
									{getAppIcon(IcoBadinet.pic)}
								</span>
								<span
									className={`header__noinet-btn-icon ${
										!isInternernetActive && 'header__noinet-btn-icon--active'
									}`}>
									{getAppIcon(IcoNoinet.pic)}
								</span>
							</Button>

							{noInetTooltip && (
								<Tooltip
									referenceElement={referenceNoInet.current}
									position={TooltipPositoinType.BOTTOM}
									text={!isInternernetActive ? toasts.noInet : tooltips.badInet}
									delay={3000}
									callbackOnHide={() => {
										setNoInetTooltip(false);
										if (tooltip?.shown) {
											toggleTooltip(null);
										}
									}}
								/>
							)}
						</div>
					)}

					{isStand && !agreementAndChatRulesModal && renderShare()}

					{isSlowModeBtn && (!currentThreadId || (currentThreadId && appLowerUsersModule)) && (
						<div className='header__slowmode-wrapper' ref={referenceSlowMode}>
							<Button
								className='header__slowmode-btn'
								onClick={() => setSlowModeTooltip(!slowModeTooltip)}>
								{getAppIcon(IcoSlowmode.pic)}
							</Button>

							{slowModeTooltip && textForSlowModeTooltip() && (
								<Tooltip
									referenceElement={referenceSlowMode.current}
									position={TooltipPositoinType.BOTTOM}
									text={textForSlowModeTooltip()}
									delay={3000}
									callbackOnHide={() => setSlowModeTooltip(false)}
								/>
							)}
						</div>
					)}

					{isScreenShareBtn && (
						<div className='header__screenshare-wrapper'>
							<Button className='header__screenshare-btn' onClick={openStreamSettingsModal}>
								{getAppIcon(IcoScreenShare.pic)}
								<span>{screenShare.title}</span>
							</Button>
						</div>
					)}

					{(currentThreadId || (!currentThreadId && !visibleThreadModal)) &&
						appEnableMessageTranslation &&
						!agreementAndChatRulesModal && (
							<div className='header__translate-wrapper' ref={referenceTranslate}>
								<Button
									className='header__translate-btn'
									onClick={
										checkUserIsOnboarding ? translateBtnClickHandler : onBoardingStageHandler
									}>
									{!translateMode.enable
										? getAppIcon(IcoTranslateOn.pic)
										: getAppIcon(IcoTranslateOff.pic)}
								</Button>

								{translateTooltip && (
									<Tooltip
										referenceElement={referenceTranslate.current}
										position={TooltipPositoinType.BOTTOM}
										text={tooltips.translate}
										delay={3000}
										center
										callbackOnHide={() => {
											setTranslateTooltip(false);
											if (tooltip?.shown) {
												toggleTooltip(null);
											}
										}}
									/>
								)}
							</div>
						)}

					{appEnableSubscriptionsFilter && (
						<div className='header__following-switcher-wrapper'>
							<Switcher
								checked={isFilterBySubscription}
								onChange={onChangeFollowingUsersFilter}
								ico={IcoFollowing.pic}
							/>
						</div>
					)}

					{appUserCounterButtonVisible &&
						!agreementAndChatRulesModal &&
						roomData?.status === RoomStatus.LIVE && <Users />}

					{appSettingsButtonVisible &&
						!agreementAndChatRulesModal &&
						((roomData?.status !== RoomStatus.SOON && !isMyTalkerModer) || isMyTalkerModer) && (
							<div className='header__settings-wrapper' ref={referenceSettings}>
								<Button
									className='header__settings-btn'
									onClick={checkUserIsOnboarding ? onSettingsHandler : onBoardingStageHandler}>
									{getAppIcon(IcoSettings.pic)}
								</Button>

								{blockedUsersToolTipVisibility && !currentThreadId && (
									<Tooltip
										referenceElement={referenceSettings.current}
										position={TooltipPositoinType.BOTTOM}
										text={tooltips.blockedUsers}
										delay={3000}
										callbackOnHide={() => {
											setBlockedUsersToolTipVisibility(false);
											if (checkShownTooltipTypeTriggered()) {
												toggleTooltip(null);
											}
										}}
									/>
								)}
							</div>
						)}

					{isCalendarButton() && !agreementAndChatRulesModal && (
						<div className='header__calendar-wrapper'>
							<Button
								className='header__calendar-btn'
								onClick={() => onShareClickHandler('calendar')}>
								{getAppIcon(IcoCalendar.pic)}
							</Button>
						</div>
					)}

					{isShareRoomButton() && !agreementAndChatRulesModal && (
						<div className='header__share-wrapper'>
							<Button className='header__share-btn' onClick={() => onShareClickHandler('share')}>
								{getAppIcon(IcoShareRoom.pic)}
							</Button>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default observer(Header);
