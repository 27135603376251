/* eslint-disable max-lines */
import StickersEmojiType from 'models/enums/StickersEmojiType.enum';
import {Dictionary} from './dictionary';

export default {
	introScreen: {
		title: 'Overeenkomst',
		subTitle: 'Licentieovereenkomst en privacybeleid',
		textFirst: 'Ik ga akkoord met de voorwaarden',
		textSecond:
			'en geef mijn toestemming voor de verwerking van persoonsgegevens op de voorwaarden zoals gedefinieerd in de',
		license: 'Licentieovereenkomst',
		policy: 'Privacybeleid',
	},
	controlPanel: {
		streamEnded: 'Uitzending is afgelopen',
		banAlert: 'Je mag niet in de chatroom schrijven',
		tabName: (tabName: StickersEmojiType) =>
			tabName === StickersEmojiType.STICKERS ? `Stickers` : `Emoji's`,
	},
	accountDeletedScreen: {
		title: 'Profiel verwijderd',
		text: (date: string) => `U kunt uw profiel herstellen tot ${date}`,
	},
	nameScreen: {
		title: 'Bijnaam',
		inputPlaceholder: 'Geef een naam op',
		nameError: 'Ongeldige naam',
	},
	avatarScreen: {
		title: 'Avatar',
		label: 'Kies iets anders',
	},
	settingsScreen: {
		title: 'Instellingen',
		personal: 'Persoonlijke instellingen',
		room: 'Chatkamer instellingen',
		profileBtn: 'Profiel',
		languageBtn: 'Taal',
		blockedBtn: 'Geblokkeerd',
		rules: 'Chatregels',
		deleteProfile: 'Profiel verwijderen',
		slowModeBtn: 'Langzame modus',
		agora: {
			microphone: 'Microfoon',
			playbackDevice: 'Uitvoerapparaat',
		},
	},
	languageScreen: {
		title: 'Taal en interface',
		messagesTranslate: 'Vertaling bericht',
		translateMessagesMode: 'Chatberichten vertalen',
		translateMessagesTo: 'Vertaal chatberichten naar',
	},
	rulesScreen: {
		title: 'Chatregels',
	},
	profileScreen: {
		title: 'Profile',
		selectAnotherPic: 'Kies een andere avatar',
		chatNameLabel: 'Bijnaam',
		changeName: 'wijziging',
	},
	blockedScreen: {
		title: 'Geblokkeerde gebruikers',
		emptyListText: 'Je hebt geen gebruikers geblokkeerd',
	},
	chatScreen: {
		inputPlaceholder: 'Bericht...',
		cantWrite: 'Je kunt niet in deze chat schrijven',
		chatSubmenu: {
			hideMessage: 'Berichten verbergen',
			showMessage: 'Berichten weergeven',
			removeFromSpeakers: 'Het woord ontnemen',
			setSpeaker: 'Het woord geven',
			hideOnlyMessage: 'Bericht verbergen',
			showOnlyMessage: 'Bericht weergeven',
			reportMessage: 'Bericht melden',
			reportUser: 'Gebruiker melden',
			muteSpeaker: 'Rustig',
			blockUser: 'Blok',
			unblockUser: 'Deblokkeren',
			reply: 'Antwoord',
			copy: 'Kopiëren',
			edit: 'Bewerking',
			delete: 'Verwijderen',
			pinMessage: 'Prikbord',
			unpinMessage: 'Losmaken',
			goToProfile: 'Open het gebruikersprofiel',
			replyInThread: 'Antwoord in thread',
		},
		actionMessage: {
			editing: 'Om te verwerken',
			reply: 'Antwoord',
		},
		status: {
			top: 'Bovenkant',
		},
		translating: 'Vertalen...',
	},
	chatUsersScreen: {
		title: 'Deelnemers',
		edited: 'Bewerkt',
		hidden: 'Verborgen',
		unreadMessages: 'Nieuwe berichten',
		usersSubmenu: {
			muteSpeaker: {
				title: 'Een deelnemer dempen',
				subtitle: 'Microfoon uitschakelen',
			},
			setSpeaker: {
				title: 'Verwijder uit speakers',
				subtitle: 'Alleen tekstchat',
			},
			removeFromSpeaker: {
				title: 'Het woord geven',
				subtitle: 'De gebruiker kan spreken',
			},
			showMessages: {
				title: 'Berichten weergeven',
				subtitle: 'Zichtbaar voor alle deelnemers',
			},
			hideMessages: {
				title: 'Berichten verbergen',
				subtitle: 'Alleen zichtbaar voor de beheerder',
			},
			blockUser: {
				title: 'Blok',
				subtitle: 'Toevoegen aan blokkeerlijst',
			},
			unblockUser: {
				title: 'Deblokkeren',
				subtitle: 'Deze gebruiker kan jouw berichten zien',
			},
			blockUserByUser: {
				title: 'Blok',
				subtitle: 'Jullie zien elkaars berichten niet',
			},
			unblockUserByUser: {
				title: 'Deblokkeren',
				subtitle: 'De gebruiker kan jouw berichten lezen',
			},
			reportUser: {
				title: 'Rapporteer gebruiker',
				subtitle: 'Klachten worden anoniem ingediend',
			},
		},
		days: {
			today: 'Vandaag',
			yesterday: 'Gisteren',
		},
		chatCreated: 'Deze chat is gestart',
	},
	alerts: {
		btns: {
			unblock: 'Deblokkeren',
			block: 'Blok',
			send: 'Versturen',
			sendAndBlock: 'Rapport verzenden en gebruiker blokkeren',
			show: 'Show',
			showAll: 'Alles weergeven',
			cancel: 'Annuleren',
			yes: 'Ja',
			hide: 'Selectie verbergen',
			hideAll: 'Verberg alles',
			make: 'Maken',
			remove: 'Verwijderen',
			delete: 'Verwijderen',
			deleteAccount: 'Delete profile',
			close: 'Afsluiten',
			pin: 'Prikbord',
			unpin: 'Losmaken',
			edit: 'Bewerking',
			end: 'Compleet',
			endAndShare: 'Voltooien en delen',
			reset: 'Opnieuw instellen',
			rules: 'Chatregels',
			contactus: 'Stuur ons een bericht',
		},
		pinMessage: {
			title: 'Dit bericht vastpinnen?',
		},
		unPinMessage: {
			title: 'Dit bericht losmaken?',
		},
		accountRestored: {
			title: 'Profiel is hersteld',
		},
		closeApp: {
			title: 'Wil je stoppen?',
		},
		removeMessage: {
			title: 'Bericht verwijderen?',
		},
		report: {
			title: 'Rapport verzenden?',
			subtitle:
				'We zullen de gebruiker niet op de hoogte stellen van uw rapport. U kunt ze ook blokkeren om elkaars berichten te verbergen.',
		},
		banUser: {
			title: (name: string) => `${name} verbannen?`,
			subtitle: (text: string) => `De gebruiker wordt geblokkeerd voor ${text}`,
		},
		unBanUser: {
			title: (name: string) => `${name} deblokkeren?`,
		},
		showUserMessages: {
			title: (name: string) => `Berichten van ${name} weergeven?`,
			subTitle: 'Andere deelnemers kunnen de berichten van deze gebruiker zien.',
		},
		hideUserMessages: {
			title: (name: string) => `Berichten van ${name} verbergen?`,
			subTitle: 'Andere deelnemers kunnen de berichten van deze gebruiker niet meer zien.',
		},
		showUserMessage: {
			title: (name: string) => `Bericht van ${name} weergeven?`,
			subTitle: 'Andere gebruikers kunnen dit bericht zien.',
		},
		hideUserMessage: {
			title: (name: string) => `Bericht van ${name} verbergen?`,
			subTitle: 'Andere deelnemers kunnen dit bericht niet zien.',
		},
		blockUserInRoom: {
			title: (name: string) => `${name} blokkeren?`,
			subTitle: 'De gebruiker kan niet deelnemen in deze chatkamer.',
		},
		unBlockUserInRoom: {
			title: (name: string) => `${name} deblokkeren?`,
			subTitle: 'De gebruiker kan deelnemen in deze chatkamer.',
		},
		blockUserInRoomByUser: {
			title: (name: string) => `${name} blokkeren?`,
			subTitle: 'Jullie zien elkaars berichten niet',
		},
		setUnsetSpeakerRole: {
			setTitle: (name: string) => `Van ${name} een spreker maken?`,
			unTitle: (name: string) => `${name} uit speakers verwijderen?`,
		},
		banInApp: {
			title: 'De beheerder heeft je geblokkeerd',
			for: 'voor',
			subtitle: (bannedUntil: string) => `Je wordt geblokkeerd tot ${bannedUntil}`,
			permanent: 'Je bent permanent geblokkeerd',
		},
		banInAppBadNickname: {
			title: 'Je bent geblokkeerd vanwege de ongepaste bijnaam',
			subtitle: 'Open je profielinstellingen, verander de bijnaam en doe opnieuw mee aan de chat.',
		},
		deleteAccount: {
			title: 'Profiel verwijderen',
			subTitle: 'Alle gegevens worden verwijderd. U kunt uw profiel binnen 30 dagen herstellen.',
		},
		pollEdit: {
			title: 'Poll bewerken?',
			subTitle: `De reacties van gebruikers worden gereset.`,
		},
		pollReset: {
			title: 'Opnieuw instellen?',
			subTitle: 'De poll wordt niet opgeslagen.',
		},
		pollEnd: {
			title: 'Wilt u de enquête invullen?',
		},
		pollDelete: {
			title: 'Poll verwijderen?',
		},
		agoraCheck: {
			updateIOSText: 'Werk de iOS-versie bij om alle hulpmiddelen van de app te gebruiken.',
			allowAccess: {
				title: 'Wil je iets zeggen?',
				text: 'Open de app-instellingen en geef toegang tot de microfoon en luidspreker.',
			},
			microphoneAndSpeakerText: 'Microfoon- en luidsprekergebruik is mogelijk sinds versie 14.3.',
			microphoneText: 'Werk de iOS-versie bij om alle hulpmiddelen van de app te gebruiken.',
			btn: 'Oké!',
		},
	},
	eventScreen: {
		title: 'Geplande gebeurtenis',
		titleSoon: 'De uitzending start binnenkort',
		subtitle: 'De chat is binnenkort beschikbaar. Even geduld aub.',
		days: 'dagen',
		hours: 'uren',
		minutes: 'minuten',
		seconds: 'seconden',
		startTitle: 'Begint bij',
	},
	toasts: {
		reciveComplaint: 'Wij hebben uw klacht ontvangen',
		setSpeakerRoleForUser: 'Gebruiker wordt aangewezen als spreker',
		unSpeakerRoleForUser: 'Gebruiker is gedempt',
		messagesShown: 'Berichten getoond',
		messagesHidden: 'Berichten verborgen',
		messageShown: 'Bericht getoond',
		messageHidden: 'Bericht verborgen',
		userBlocked: 'Gebruiker geblokkeerd',
		userUnBlocked: 'Gebruiker gedeblokkeerd',
		youAreSpeaker: 'Jij bent de spreker! Zeg iets',
		youAreNotSpeaker: 'Je bent gedempt',
		userIsSpeaker: (name: string) => `${name} is nu de spreker`,
		userNotSpeaker: (name: string) => `${name} is geen spreker meer`,
		yourUserNameInChat: (name: string) => `Je bijnaam is ${name}`,
		messageRemoved: 'Bericht verwijderd',
		messageCopied: 'Tekst gekopieerd',
		obscenities: 'Controleer of uw bericht niet in strijd is met onze communityrichtlijnen.',
		requestSent: 'Verzoek verzonden',
		requestDecline: `Je hebt nog geen microfoon gekregen`,
		youareblocked: (text: string) =>
			`Je bent geblokkeerd voor ${text}. Je kunt geen berichten sturen.`,
		youareunblocked: 'Je bent gedeblokkeerd. U kunt berichten verzenden.',
		youAreModerator: 'Jij bent moderator!',
		youAreNoModerator: 'Je bent geen moderator meer',
		micMuted: 'Je microfoon is gedempt',
		noInet: 'Geen internetverbinding',
		messagePinned: 'Bericht vastgezet',
		messageUnpinned: 'Bericht losgemaakt',
		pollPublished: 'Enquête gepubliceerd',
		pollPublishedError: 'Fout! Probeer het later opnieuw',
		pollAddPic: 'Foto toevoegen',
		pollAddPicError: 'Kies een andere',
		pollDeleted: 'Enquête verwijderd',
		pollEnded: 'Enquête voltooid',
		sendStickerWhenSlowModeIsOn: (time: string) => `Wacht ${time} voordat je een sticker verstuurt`,
		streamConnecting: 'Verbinding maken met de stream...',
		linkProhibited: 'Je kunt geen links in de chat versturen',
		phoneProhibited: 'Telefoonnummers kunnen niet worden gedeeld in de chat',
		profileHidden: 'Gebruiker heeft zijn profiel verborgen',
		imageSizeWarning: 'kunt een afbeelding uploaden tot 10 MB',
		imageBlocked: 'De moderator heeft het uploaden van afbeeldingen geblokkeerd',
		streamKeyCopied: 'Broadcastsleutel gekopieerd',
	},
	btns: {
		acceptandgo: 'Accepteren en doorgaan',
		saveandgo: 'Opslaan en doorgaan',
		save: 'Opslaan',
		unblock: 'Deblokkeren',
		block: 'Blok',
		restoreAccount: 'Profiel herstellen',
		close: 'Afsluiten',
	},
	coopyrights: ' Aangedreven door Watchers.io',
	modals: {
		report: {
			title: (target: 'message' | 'user') =>
				target === 'message' ? 'Report the message' : 'Meld deze gebruiker voor',
			subtitle: 'Klachten worden anoniem verzonden',
			btns: {
				violence: 'Geweld',
				provocations: 'Provocaties',
				personalDetails: 'Persoonlijke gegevens',
				flood: 'Overspoelen',
				spam: 'Spam',
				fraudBegging: 'Fraude of bedelen',
				other: 'Ander',
			},
		},
		ban: {
			title: 'Blokkeer deze gebruiker',
			subtitle: 'Geef de reden voor blokkering op',
			btns: {
				insults: 'Beledigingen, bedreigingen, vloeken',
				personal: 'Persoonsgegevens',
				flood: 'Overspoelen',
				links: 'Links of advertenties van diensten van derden',
				violence: 'Geweld',
				fraud: 'Fraude of bedelen',
				nickname: 'Verboden bijnaam',
				politicial: 'Politieke, geseksualiseerde en andere provocaties',
				repeated: 'Herhaalde overtreding',
				auto: 'Klachten van chatgebruikers',
				spam: 'Spam',
			},
		},
		waitingSpeakers: {
			text: 'Geen verzoeken',
			handsUp: 'Verzoeken om te spreken',
			waiting: 'In afwachting van deelnemers',
		},
		poll: {
			supTitles: {
				typeQuiz: 'Kies een juist antwoord',
				typeQuizMultiple: 'Controleer de juiste opties',
				typePoll: 'Deel uw mening',
				typePollMultiple: 'Er zijn verschillende opties',
				default: 'Publieke peiling',
			},
			btns: {
				reply: 'Antwoord',
				vote: 'Stemmen',
			},
		},
		pollEdit: {
			supTitles: {
				typeQuiz: 'Quiz',
				typePoll: 'weinig antwoorden',
				default: 'Publieke peiling',
			},
			btns: {
				edit: 'Poll bewerken',
				end: 'Compleet',
			},
		},
		pollCreated: {
			titles: {
				created: 'Een poll maken',
				typeQuiz: 'Quiz',
				typePoll: 'weinig antwoorden',
				default: 'Publieke peiling',
			},
		},
		changeName: {
			title: 'Change your nickname',
			subtitle: 'Je bent geblokkeerd vanwege een ongeldige bijnaam',
		},
		temporaryAgreement: {
			title: 'Lees de chatregels',
			rules: {
				bePolite: {
					title: 'Wees beleefd',
					text: 'Stuur geen vijandige en obscene berichten.',
				},
				beAttentive: {
					title: 'Wees oplettend',
					text: 'Deel geen persoonlijke gegevens en vraag er ook niet om.',
				},
				notAdvertise: {
					title: 'Verkoop niets',
					text: 'Deel in plaats daarvan uw mening en emoties.',
				},
			},
			btns: {
				allRules: 'Lees alle regels',
				acceptAndContinue: 'Begrepen!',
			},
		},
		slowMode: {
			title: 'Langzame modus',
			text: 'Deelnemers kunnen niet vaker dan het gekozen tijdsinterval berichten in de chat verzenden.',
			enable: 'Langzame modus inschakelen',
			disable: 'Langzame modus uitschakelen',
			sendTimeout: 'Set timeout',
		},
		slowModeTimeout: {
			title: 'Time-out instellen',
		},
		chatBot: {
			title: 'Chatbot-gebruik',
			rules: [
				{
					title: 'Kunstmatige intelligentie',
					text: 'De bot bootst menselijke communicatie na en verschaft informatie op basis van zijn kennis. Bedenk echter dat zijn reacties beperkt kunnen zijn en mensen niet zullen vervangen.',
				},
				{
					title: 'De bot kan fouten maken',
					text: 'Wij kunnen de juistheid en nauwkeurigheid van de door de bot verstrekte informatie niet garanderen.',
				},
			],
			btns: {
				close: 'Sluiten',
			},
		},
		shareBet: {
			title: 'Deel je weddenschap',
			noBetsTitle: 'Je hebt geen weddenschappen om te delen',
			noBetsText: 'U kunt de weddenschappen delen die u in de afgelopen 14 dagen hebt geplaatst.',
			btns: {
				share: 'Deel weddenschap',
				copy: 'Kopieer weddenschap',
			},
			status: {
				null: 'In behandeling',
				win: 'Won',
				lose: 'Kwijt',
			},
			betTypeV1: {
				express: 'NADRUKKELIJK',
				single: 'ENKEL',
			},
			betTypeV2: {
				single: 'Enkel',
				multi: 'Multi',
				system: 'Systeem',
			},
			totalFactor: 'Inzet',
			bet: 'Weddenschap',
			showMore: 'Meer weergeven',
			betRepeated: 'Gekopieerde weddenschap',
			times: ['tijd', 'tijden', 'tijden'],
		},
		shareGamble: {
			noGamblesText:
				'Je hebt nog geen winst om te delen. Blijf spelen en je volgende winst verschijnt hier om te delen met andere spelers!',
		},
		record: {
			text: 'De opname wordt verwerkt...',
		},
		agoraDevices: {
			title: 'Audio-instellingen',
			text: 'Controleer of de juiste microfoon is geselecteerd.',
		},
		translate: {
			title: 'Vertaling van berichten',
			chooseLanguage: 'Selecteer taal',
			descr: 'Berichten worden automatisch vertaald.',
			btn: 'Vertalen',
		},
		streamSettings: {
			title: 'Streaminstellingen',
			description: 'Installeer een video-encoder en voer de streamsleutel in de instellingen in.',
			shareScreen: {
				title: 'Scherm delen',
				description: 'Geen speciale instellingen vereist',
			},
			rtmp: {
				title: 'Externe streambron',
				description: 'Gebruik aanvullende software om de feed te streamen.',
			},
			streamKey: 'Stream key',
			showStreamKey: 'Show',
			hideStreamKey: 'Verbergen',
			btns: {
				continue: 'Doorgaan',
				close: 'Sluiten',
				ok: 'Klaar',
			},
		},
	},
	pinnedMessage: {
		title: 'Bericht vastgezet',
	},
	errorPage: {
		error: 'Fout',
		types: [
			// errorNumber 0
			{
				title: 'Wij herkennen u niet',
				text: 'Probeer de kamer opnieuw binnen te gaan.',
			},
			// errorNumber 1
			{
				title: 'We kunnen de kamer niet vinden',
				text: 'Probeer opnieuw binnen te komen.',
			},
			// errorNumber 2
			{
				title: 'Kan de chatkamer niet laden',
				text: 'Start opnieuw op en probeer het opnieuw',
			},
			// errorNumber 3
			{
				title: 'Wij herkennen u niet',
				text: 'De kamer is niet beschikbaar in de incognitomodus.',
			},
			// errorNumber 4
			{
				title: 'Ongeldige bijnaam',
				text: 'Wijzig uw bijnaam in de profielinstellingen.',
			},
		],
	},
	tooltips: {
		emotions: 'Vasthouden om te veranderen',
		badInet: 'Slechte netwerkverbinding',
		blockedUsers: 'Controleer uw lijst met geblokkeerde gebruikers',
		waitingSpeakers: 'Iemand wil spreken',
		wantSpeak: 'Steek je hand op als je wilt spreken',
		slowMode: {
			header: (time: string) =>
				`Slow mode is aan. Gebruikers kunnen één bericht per ${time} sturen, maar jij bent immuun.`,
			btnSendMessage: (time: string) => `nJe kunt elke ${time} een bericht verzenden.`,
		},
		shareBet: 'Deel een weddenschap in de chat',
		translate: 'Vertalen naar de gewenste taal',
	},
	poll: {
		toggleSubmenu: {
			create: 'Een poll maken',
			edit: 'Bewerking',
			show: 'Weergave',
			end: 'Compleet',
		},
		tooltip: {
			btn: 'Stemmen',
		},
		settings: {
			title: 'Instellingen',
			titleAdditionally: 'Meer',
			switches: {
				quizMode: 'Quiz-modus',
				multipleChoice: 'Meervoudige keuze van antwoorden',
				withPhoto: `Poll met foto's`,
			},
		},
		question: {
			title: 'Vraag',
			inputPlaceholder: 'Stel een vraag',
		},
		message: {
			title: 'Je hebt gestemd',
		},
		answers: {
			title: 'Antwoordopties',
			warning: 'U kunt maximaal 5 antwoorden toevoegen',
		},
		answer: {
			tooltip: 'Kies een juist antwoord',
			inputPlaceholder: 'Antwoord',
		},
		results: {
			text: 'Resultaten',
			votes: 'Stemmen',
			// words: ['stemmen', 'stemmen', 'stemmen'],
		},
		btns: {
			addQuestion: 'Vraag toevoegen',
			addAnswers: 'Antwoorden toevoegen',
			addAnswer: 'Antwoord toevoegen',
			publish: 'Publiceren',
			vote: 'Stemmen',
		},
	},
	feedback: {
		audio: {
			btns: {
				rate: 'Tarief',
				continue: 'Volgende',
				checkConnection: 'Test uw verbinding',
				close: 'Uitgang',
			},
			quality: {
				title: 'Geef alstublieft een beoordeling van de geluidskwaliteit',
			},
			reason: {
				title: 'Wat was er mis?',
			},
			connection: {
				title: 'Test uw verbinding alstublieft',
				text: 'Het duurt maximaal een minuut en helpt ons de oorzaak van het probleem te vinden.',
			},
			statistics: {
				title: 'Tests uitvoeren...',
			},
			done: {
				title: 'Bedankt!',
				text: 'Wij onderzoeken het probleem en lossen het op.',
			},
		},
	},
	chatBot: {
		message: 'Stel je vraag aan de AI-bot...',
		warnings: {
			disabled: 'Ik ben afgesloten',
			overloaded: 'Wij zijn overbelast',
		},
	},
	audioReason: {
		nothear: 'Kon niets horen',
		connection: 'Het geluid was verbroken',
		microphone: 'Microfoon werkte niet',
	},

	copybetting: {
		orderStatus: {
			won: 'Gewonnen',
			lost: 'Verloren',
			new: 'Nieuw',
			pending: 'In behandeling',
			cashedOut: 'Uitbetaald',
			boreDraw: 'Nul-nul',
			cashback: 'Geld terug',
			megaBetNew: 'MegaBet Nieuw',
			megaBetCanceledByOperator: 'MegaBet Geannuleerd door operator',
			megaBetCanceledByPlayer: 'MegaBet Geannuleerd door speler',
			megaBetCanceledBySystem: 'MegaBet Geannuleerd door systeem',
			betGamesPending: 'BetGames In behandeling',
			betGamesWon: 'BetGames Won',
			betGamesLost: 'BetGames Lost',
			paid: 'Paid',
			initialWin: 'Initial Win',
			autoBetCanceledBySystem: 'AutoBet Geannuleerd door systeem',
			autoBetCanceledByPlayer: 'AutoBet Geannuleerd door speler',
			autobetPending: 'Autobet In behandeling',
			goalAhead: 'Doel vooruit',
			canceled: 'Geannuleerd',
			return: 'Teruggave',
		},
		stakeStatus: {
			pending: 'In behandeling',
			won: 'Won',
			lost: 'Kwijt',
			return: 'Teruggave',
			lostReturn: 'Kwijt | Teruggave',
			wonReturn: 'Won | Teruggave',
			canceled: 'Geannuleerd',
			initialReturn: 'Initial Teruggave',
			pendingInitialReturn: 'In behandeling | Initiële teruggave',
			wonInitialReturn: 'Won | Initiële teruggave',
		},
	},
	screenShare: {
		title: 'Scherm delen',
		stopSharing: 'Stop met delen',
		modal: {
			title: 'Scherm delen is alleen beschikbaar op desktop',
			text: `Open de chat op uw computer en klik op 'Scherm delen' in de rechterbovenhoek.`,
			btn: 'Kopieer de uitnodigingslink',
		},
	},
	threads: {
		title: 'Draad',
		back: 'Terug',
		replies: ['antwoord', 'antwoorden', 'antwoorden'],
	},
	attach: {
		submenu: {
			sendImage: 'Afbeelding verzenden',
			shareBet: 'Deel weddenschap',
			shareGamble: 'Delen winnen',
		},
		images: ['afbeelding', 'afbeeldingen', 'afbeeldingen'],
		addCaption: 'Bijschrift toevoegen',
	},
	following: {
		following: 'Volgend',
	},
} as unknown as Dictionary;
